import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import pathNames from 'utils/path-names';
import styles from './AdsSmall.module.css'
import projectConfig from '_customization/project-config'
import Button from 'components/_shared/button/Button'
import { useEffect, useState } from 'react'
import { handleSetLanguageStyle } from 'utils/shared-methods'

function AdsSmall() {
  const { t } = useTranslation();

  const [translatedDeStyle, setTranslatedDeStyle] = useState('');
  const [translatedTrStyle, setTranslatedTrStyle] = useState('');

    useEffect(() => {
        handleSetLanguageStyle("de", setTranslatedDeStyle);
        handleSetLanguageStyle("tr", setTranslatedTrStyle);
    }, [window.localStorage.getItem('i18nextLng')]);

  const navigate = useNavigate();
  function onBuyAnchor() {
      navigate(pathNames.exchange);
  }
  function onReadMore(){
      navigate(pathNames.fees);
  }
  return <div className={styles.container}>
      <div className={styles.title}>
          {t('openPosition.Saving_text')} <span className={styles.titleColor}>{t('openPosition.60%_text')}</span><br />
          {t('openPosition.HoldingAnchor_object.text1')}
          {" " + projectConfig.tokenName}
          {!translatedDeStyle.length ? " " : ""}
          {t('openPosition.HoldingAnchor_object.text2')}
      </div>
      <div className={styles.r1}>
          <Button variant="ads-small" onClick={onBuyAnchor}>
              {t('openPosition.BuyProjectToken_object.text1')}
              {" " + projectConfig.tokenName}
              {!translatedTrStyle.length ? " " : ""}
              {t('openPosition.BuyProjectToken_object.text2')}
          </Button>
          <div className={styles.readMore} onClick={onReadMore}>
            {t('openPosition.readmore_text')}
          </div>
        </div>
    </div>
}
export default AdsSmall;