import { ReactComponent as DashboardIcon } from "assets/dashboard.svg";
import { ReactComponent as CharityIcon } from "assets/charity.svg";
import { ReactComponent as ExpandMoreIcon } from "assets/expand-more.svg";
import { ReactComponent as ExchangeIcon } from "assets/exchange.svg";
import { ReactComponent as PositionHistoryIcon } from "assets/position_history.svg";
import { ReactComponent as FeesIcon } from "assets/fees.svg";
import { ReactComponent as RefIcon } from "assets/referral-overview-icon.svg";

import projectIcon from "_customization/assets/project-icon.png";
import className from "classnames";
import ConnectModal from "components/connect-modal/connectModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onDisconnect } from "utils/conncetioncode";
import { useNavigate } from 'react-router-dom';
import styles from "./header.module.css";
import { Link, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import pathNames from "utils/path-names";
import FeeLevelPopup from "./fee-level-popup";
import { useMediaQuery } from "react-responsive";
import usePosition from "hooks/usePosition";
import useBalance from "hooks/useBalance";
import { calcLevel } from "utils/feeDiscountLevel";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import LanguageTabs from "./MultiLanguage";

import projectConfig from '_customization/project-config'
import Button from 'components/_shared/button/Button'
import useReferrals from 'hooks/useReferrals'
import { handleSetLanguageStyle } from 'utils/shared-methods'

function MoreElement(id, namePath, href) {
  this.id = id;
  this.namePath = namePath;
  this.href = href;
}
const moreArray = [
  new MoreElement('projectName', projectConfig.parentWebsiteName, projectConfig.externalLinks.website),
  new MoreElement('docs',"header.Documentation_text", projectConfig.externalLinks.docs),
  new MoreElement('tutorial', "header.Tutorial_text", projectConfig.externalLinks.docsTutorial),
  new MoreElement('telegram',"header.Telegram_text", projectConfig.externalLinks.telegram),
  new MoreElement('twitter', "header.Twitter_text", projectConfig.externalLinks.twitter),
  new MoreElement('youTube',"header.YouTube_text", projectConfig.externalLinks.youtube),
  new MoreElement('instagram',"header.Instagram_text", projectConfig.externalLinks.instagram),
  new MoreElement('medium', "header.Medium_text", projectConfig.externalLinks.medium),
  new MoreElement('chart',"header.Chart_text", projectConfig.externalLinks.chart)
]

function Header() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const USDCBalance = useSelector((state) => state.USDCBalance);
  const userAddress = useSelector((state) => state.userAddress);
  const positionsUpdate = useSelector((state) => state.positionsUpdate);
  const web3 = useSelector((state) => state.web3);
  const balanceUpdate = useSelector((state) => state.balanceUpdate);
  const { getUserBalance } = usePosition();
  const { getAnchorBalance } = useBalance();
  const { addNewRefShare } = useReferrals();
  const selectedPool = useSelector((state) => state.selectedPool);
  useEffect(() => {
    if (web3 && userAddress) {
      getUserBalance().then((ret) => {
        if (ret) {
          dispatch({ type: "USDCBalanceChange", USDCBalance: +ret.balance });
          setEscrow(+ret.escrow);
        }
      });
      getAnchorBalance().then((res) => {
        if (res) setAnchorBalance(res);
      });
    }
  }, [userAddress, web3, balanceUpdate, positionsUpdate, selectedPool]);

  // Adding referral when connectModal.js is not called but wallet is connected
  useEffect(() => {
    if (!userAddress) return;

    const refId = window.localStorage.getItem('refID');
    if (refId) {
      addNewRefShare(userAddress);
    }
  }, [userAddress]);

  const [show, setShow] = useState(false);
  const [escrow, setEscrow] = useState(null);
  const [anchorBalance, setAnchorBalance] = useState(null);
  const pathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const [showFeePopup, setShowFeePopup] = useState(false);
  const [dynamicStyleSuffix, setDynamicStyleSuffix] = useState('')
  const [translatedLangStyle, setTranslatedLangStyle] = useState('')
  const isSmallScreen = useMediaQuery({ query: "(max-width: 450px)" });
  const isLargeScreen = !isSmallScreen;
  useEffect(() => {
    //if (userAddress) { setShow(false); }
  }, [userAddress]);

  useEffect( () => {
    // ** Finds ref address
    const refID = searchParams.get('ref');
    if (refID !== undefined) {
      window.localStorage.setItem('refID', refID)
    }

    // ** Defines dynamic position of the More
    if (projectConfig.configs.topHeader) {
      setDynamicStyleSuffix('WithTopHeader')
    }
  }, []);
  useEffect(() => {
    handleSetLanguageStyle("tr", setTranslatedLangStyle)
  }, [window.localStorage.getItem('i18nextLng')])

  function onConnectBtnClick() {
    if (userAddress) {
      dispatch({ type: "web3Change", web3: null, userAddress: null });
      onDisconnect();
      navigate(0);
    } else {
      setShow(true);
    }
  }

  return (
    <div>
      {show && <ConnectModal setShow={setShow} />}
      <div className={styles.container}>
        {isSmallScreen && (
          <>
            <div className={styles.row1}>
              <div className={styles.logo}>
                <a href={projectConfig.externalLinks.website} target="_blank">
                  <img src={projectIcon} alt="Anchor logo"/>
                </a>
              </div>
              <div className={styles.actionBtn}>
                <LanguageTabs />
              </div>
              <div className={styles.growSpace}></div>
              <Button variant="connect-wallet" onClick={onConnectBtnClick}>
                {userAddress
                  ? userAddress.slice(0, 4) + "..." + userAddress.slice(-4)
                  : t("header.Connect_wallet_text")}
              </Button>
            </div>
            <div className={styles.row2}>
              <div className={styles.balance}>
                <div className={styles.balanceR1}>USDC {t("header.Balance_text")}</div>
                <div className={styles.balanceR2}>
                  {USDCBalance?.toFixed(2) ?? ""}
                </div>
              </div>
              <div className={styles.separator}></div>
              <div className={styles.balance}>
                <div className={styles.balanceR1}>USDC {t("header.Escrow_text")}</div>
                <div className={styles[`balanceR2${translatedLangStyle}`]}>
                  {escrow?.toFixed(2) ?? ""}
                </div>
              </div>
              {projectConfig.configs.tokenHold &&
                  <div
                    onMouseEnter={() => setShowFeePopup(true)}
                    className={styles.anchor}
                  >
                    <img src={projectIcon} className={styles.anchorImg} alt="Anchor logo"/>
                    <div>
                      <div className={styles.anchor1}>{projectConfig.tokenName} #hodl</div>
                      <div className={styles.anchor2}>
                        {anchorBalance}
                      </div>
                    </div>
                    <div className={styles.circleNumber}>
                      {calcLevel(anchorBalance)}
                    </div>
                    {showFeePopup && (
                      <FeeLevelPopup
                          level={calcLevel(anchorBalance)}
                          onClose={() => setShowFeePopup(false)}
                      />
                    )}
              </div>}
            </div>
            <div className={styles.bottomMenu}>
              <Link to={pathNames.margin}>
                <div
                  className={className(
                    styles.bottomMenuItem,
                    pathname === pathNames.margin
                      ? styles.bottomMenuItemSelected
                      : null
                  )}
                >
                  <DashboardIcon className={styles.bottomMenuItemImg} />
                  <div>{t("header.Margin_text")}</div>
                </div>
              </Link>
              <Link to={pathNames.lending}>
                <div
                  className={className(
                    styles.bottomMenuItem,
                    pathname === pathNames.lending
                      ? styles.bottomMenuItemSelected
                      : null
                  )}
                >
                  <CharityIcon className={styles.bottomMenuItemImg} />
                  <div>{t("header.Earning_text")}</div>
                </div>
              </Link>
              <Link to={pathNames.exchange}>
                <div
                  className={className(
                    styles.bottomMenuItem,
                    pathname === pathNames.exchange
                      ? styles.bottomMenuItemSelected
                      : null
                  )}
                >
                  <ExchangeIcon className={styles.bottomMenuItemImg} />
                  <div>{t("header.Exchange_text")}</div>
                </div>
              </Link>
              {projectConfig.configs.tokenHold &&
                  <Link to={pathNames.fees}>
                    <div
                        className={className(
                            styles.bottomMenuItem,
                            pathname === pathNames.fees
                                ? styles.bottomMenuItemSelected
                                : null
                        )}
                    >
                      <FeesIcon className={styles.bottomMenuItemImg}/>
                      <div>{t("header.Fees_text")}</div>
                    </div>
              </Link>}
              <Link to={pathNames.positions_and_history}>
                <div
                  className={className(
                    styles.bottomMenuItem,
                    pathname === pathNames.positions_and_history
                      ? styles.bottomMenuItemSelected
                      : null
                  )}
                >
                  <PositionHistoryIcon className={styles.bottomMenuItemImg} />
                  <div>{t("header.Positions_text")}</div>
                </div>
              </Link>
              <Link to={pathNames.referral_overview}>
                <div
                  className={className(
                    styles.bottomMenuItem,
                    pathname === pathNames.referral_overview
                      ? styles.bottomMenuItemSelected
                      : null
                  )}
                >
                  <RefIcon className={styles.bottomMenuItemImg} />
                  <div>{t("header.Referrals_text")}</div>
                </div>
              </Link>
            </div>
          </>
        )}
        {isLargeScreen && (
          <>
            <div className={styles.logo}>
              <a href={projectConfig.externalLinks.website} target="_blank">
                <img src={projectIcon} alt="Anchor logo" />
              </a>
            </div>
            <div className={styles.separator1}></div>
            <div className={styles.actionBtn}>
              <LanguageTabs />
              <div className={styles.dropMenu}></div>
              {/* <Language/> */}
            </div>
            <div className={styles.growSpace}></div>

            <div className={styles.separator1}></div>
            <Link to={pathNames.margin}>
              <div
                className={className(
                  styles.actionBtn,
                  pathname === pathNames.margin ? styles.actionBtnSelected : null
                )}
              >
                <DashboardIcon className={styles.actionBtnImg} />
                {t("header.Margin_text")}
              </div>
            </Link>
            <Link to={pathNames.lending}>
              <div
                className={className(
                  styles.actionBtn,
                  pathname === pathNames.lending
                    ? styles.actionBtnSelected
                    : null
                )}
              >
                <CharityIcon className={styles.actionBtnImg} />
                <div>{t("header.Earning_text")}</div>
              </div>
            </Link>
            <Link to={pathNames.exchange}>
              <div
                className={className(
                  styles.actionBtn,
                  pathname === pathNames.exchange
                    ? styles.actionBtnSelected
                    : null
                )}
              >
                <ExchangeIcon className={styles.actionBtnImg} />
                {t("header.Exchange_text")}
              </div>
            </Link>
            <div className={classNames(styles.actionBtn, styles.moreAction)}>
              <div className={styles[`moreActionword${translatedLangStyle}`]}>
                {t("header.More_text")}
              </div>
              <ul className={styles[`dropMenu${dynamicStyleSuffix}`]}>
                {moreArray.map((item) => {
                  if (item.href) {
                    return (
                        <li key={item.id}>
                          <a href={item.href} target="_blank">
                            {t(item.namePath)}
                          </a>
                        </li>
                    )
                  }
                })}
              </ul>
              <ExpandMoreIcon className={styles.actionBtnImg} />
            </div>
            <div className={styles.growSpace}></div>

            <div className={styles.balance}>
              <div className={styles.balanceR1}>
                USDC {t("header.Balance_text")}
              </div>
              <div className={styles.balanceR2}>
                ${USDCBalance?.toFixed(2) ?? ""}
              </div>
            </div>
            <div className={styles.balance}>
              <div className={styles.balanceR1}>
                USDC {t("header.Escrow_text")}
              </div>
              <div className={styles[`balanceR2${translatedLangStyle}`]}>
                ${escrow?.toFixed(2) ?? ""}
              </div>
            </div>
            {projectConfig.configs.tokenHold &&
                <div className={styles.balance}>
                <div className={styles.balanceR1}>{projectConfig.tokenName} #hodl</div>
                <div
                    onMouseEnter={() => setShowFeePopup(true)}
                    className={styles.balanceAnc}
                >
                  {anchorBalance}
                </div>
                <div className={styles.circleNumber}>
                  {calcLevel(anchorBalance)}
                </div>
                {showFeePopup && (
                    <FeeLevelPopup
                        level={calcLevel(anchorBalance)}
                        onClose={() => setShowFeePopup(false)}
                    />
                )}
            </div>}
            <div className={styles.separator2}></div>

            <Button variant="connect-wallet" onClick={onConnectBtnClick}>
              {userAddress
                ? userAddress.slice(0, 4) + "..." + userAddress.slice(-4)
                : t("header.Connect_wallet_text")}
            </Button>
          </>
        )}
      </div>
      <div className={"horizontalSeparator"}></div>
    </div>
  );
}

export default Header;
