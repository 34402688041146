import loading from "_customization/assets/loading.gif";
import classnames from "classnames";
import AdsSmall from "components/ads/AdsSmall";
import Help from "components/help/help";
import SelectToken from "components/select-token/select-token";
import usePool from "hooks/usePool";

import usePosition from "hooks/usePosition";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styles from "./open-position.module.css";
import Button from 'components/_shared/button/Button'
import projectConfig from '_customization/project-config'
import BigNumber from 'bignumber.js';

function OpenPosition() {
  const { t } = useTranslation();

  const { getAvailableSupply, getNewPositionData, getMaxLeverage } = usePool();
  const { openPosition } = usePosition();
  const selectedPool = useSelector((state) => state.selectedPool);
  const USDCBalance = useSelector((state) => state.USDCBalance);
  const [commitment, setCommitment] = useState("");
  const [leverage, setLeverage] = useState(3);
  const [selected, setSelected] = useState("Long");
  const [availableSupply, setAavailableSupply] = useState(null);
  const [positionData, setPositionData] = useState({
    totalSize: 0,
    liquidationFee: 0,
    entryPrice: 0,
    impact: 0,
  });
  const [takeProfit, setTakeProfit] = useState("");
  const [stopLoss, setStopLoss] = useState("3");
  const [inprogress, setInprogress] = useState(false);
  const dispatch = useDispatch();
  const [maxLeverage, setMaxLeverage] = useState(null);
  const minLeverage = 2;
  const leverage1 =
    minLeverage && maxLeverage
      ? (minLeverage + (maxLeverage - minLeverage) / 3).toFixed(0)
      : null;
  const leverage2 =
    minLeverage && maxLeverage
      ? (minLeverage + (2 * (maxLeverage - minLeverage)) / 3).toFixed(0)
      : null;
  const totalCommitment = useMemo(() => (
    positionData?.liquidationFee !== null && commitment?.length > 0
      ? Number(commitment) + Number(positionData.liquidationFee)
      : null
  ), [commitment, positionData.liquidationFee]);
  const notHaveEnoughFunds =
    totalCommitment !== null &&
    USDCBalance !== null &&
    totalCommitment > USDCBalance;
  const periodicUpdate = useSelector((state) => state.periodicUpdate);


  useEffect(() => {
    if (selectedPool && commitment?.length > 0) {
      getNewPositionData(
        selectedPool.title,
        selected === "Short",
        commitment,
        leverage
      ).then((res) => {
        if (res && typeof res === "object") setPositionData(res);
        //console.log(res);
      });
    }
  }, [periodicUpdate]);
  useEffect(() => {
    setMaxLeverage(null);
    getMaxLeverage(selectedPool.title).then((res) => {
      const maxLeverage = (new BigNumber(res)).toNumber();
      setMaxLeverage(maxLeverage);
    });
  }, [selectedPool, selected]);
  useEffect(() => {
    setPositionData({
      totalSize: 0,
      liquidationFee: 0,
      entryPrice: 0,
      impact: 0,
    });
    //console.log(commitment);
    if (selectedPool && commitment?.length > 0) {
      getNewPositionData(
        selectedPool.title,
        selected === "Short",
        commitment,
        leverage
      ).then((res) => {
        if (res && typeof res === "object") setPositionData(res);
        //console.log(res);
      });
    }
  }, [selectedPool, leverage, commitment, selected]);

  useEffect(() => {
    if (selectedPool) {
      getAvailableSupply(selectedPool.title, selected === "Short").then(
        (res) => {
          if (res) setAavailableSupply(+res);
        }
      );
    }
  }, [selectedPool, selected]);
  useEffect(() => {
    if (selectedPool.title === "ANCHOR" && selected === "Short") {
      setSelected("Long");
    }
  }, [selectedPool]);
  function openPositionHandler() {
    const options = {
      isShort: selected === "Short",
      token: selectedPool.title,
      totalSize: Number(positionData?.totalSize),
      leverage: leverage,
      takeProfit: checked && takeProfit?.length > 0 ? +takeProfit : 0,
      stopLoss: checked && stopLoss?.length > 0 ? +stopLoss : 0,
    };
    if (
      !notHaveEnoughFunds &&
      positionData &&
      selectedPool &&
      commitment?.length > 0
    ) {
      setInprogress(true);
      openPosition(options).then((res) => {
        if (res && res.status === true)
          dispatch({
            type: "showMessageChange",
            showMessage: true,
            txlink: res.txlink,
            messageTitle: t("transactionPopups.Opened_text"),
          });
        setInprogress(false);
        dispatch({ type: "positionsUpdateChange" });
        dispatch({ type: "balanceUpdateChange" });
        //console.log(res)
      });
    }
  }
  var textCss = [null, styles.unselected];
  if (selected === "Short") {
    textCss = [styles.unselected, null];
  } else if (selected === "Long") {
  }

  const [checked, setChecked] = useState(false);
  var checkCss = null;
  var checkMark = "✔";
  if (checked === false) {
    checkMark = "";
    checkCss = styles.hide2;
  }
  function onMax() {
    const blnc = String(USDCBalance);
    if ((selectedPool && blnc !== null) || blnc !== undefined) {
      getNewPositionData(
        selectedPool.title,
        selected === "Short",
        blnc,
        leverage
      ).then((res) => {
        if (
          res &&
          typeof res === "object" &&
          res.liquidationFee !== null &&
          res.liquidationFee !== undefined
        ) {
          const maxCommitment = Number(blnc) - Number(res.liquidationFee);
          setCommitment(maxCommitment?.toString());
        }
      });
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.row1}>
          {t('openPosition.Title_object.TRADINGWINDOW_text')}
          <Help
            shortMessage={t('openPosition.Title_object.shortMessage_text')}
            longMessage={t('openPosition.Title_object.longMessage_text')}
          />
        </div>
        <div className={styles.selectionRow}>
          <div className={styles.drop}>
            <SelectToken />
          </div>
          <div className={styles.tabContainer}>
            <div
              className={styles.selectionCol}
              onClick={() => {
                setSelected("Long");
              }}
            >
              <div className={textCss[0]}>
                <div className={styles.tabtitle}>
                {t('openPosition.Long_object.Long_text')}

                  <Help
                    onLeft={true}
                    shortMessage={t('openPosition.Long_object.shortMessage_text')}
                    longMessage={t('openPosition.Long_object.longMessage_text')}
                  />
                </div>
              </div>
            </div>
            {selectedPool.title !== "ANCHOR" && (
              <div
                className={styles.selectionCol}
                onClick={() => {
                  setSelected("Short");
                }}
              >
                <div className={textCss[1]}>
                  <div className={styles.tabtitle}>
                  {t('openPosition.Short_object.Short_text')}

                    <Help
                      onLeft={true}
                      shortMessage={t('openPosition.Short_object.shortMessage_text')}
                      longMessage={t('openPosition.Short_object.longMessage_text')}
                    />
                  </div>
                </div>
              </div>
            )}
            <hr
              className={classnames(
                styles.hr,
                selected === "Short" && styles.shortTab
              )}
            />
          </div>
        </div>
        <div className={styles.currentRow}>
          <div className={styles.currentCol}>
            <div className={styles.currentHead}>{t('openPosition.TotalSize_text')}:</div>
            <div className={styles.currentPrice}>
              {positionData?.totalSize &&
                Number(positionData.totalSize).toFixed(4)}
              <span className={styles.currentUnit}>
                {selected === "Long" ? "USDC" : selectedPool?.title}
              </span>
            </div>
          </div>
          <div className={styles.currentCol}>
            <div className={styles.currentHead}>{t('openPosition.AvailableSupply_text')}:</div>
            <div className={styles.currentPrice}>
              {availableSupply?.toFixed(4)}
              <span className={styles.currentUnit}>
                {selected === "Long" ? "USDC" : selectedPool?.title}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.commit}>{t('openPosition.Commit_text')}:</div>
        <div className={styles.inpContainer}>
          <input
            type="number"
            className={styles.input}
            value={commitment}
            onChange={(evt) => {
              setCommitment(evt.target.value);
            }}
          ></input>
          <button className={styles.maxButton} onClick={onMax}>
            {t("topup.Max_text")}
          </button>
        </div>
        {positionData?.totalSize !== null &&
          !!availableSupply &&
          Number(availableSupply) < Number(positionData?.totalSize) && (
            <div className={styles.error2}>
              {t("openPosition.ErrorMessages.noLiquidity")}
            </div>
          )}
        <div className={styles.liquidation}>
          <span className={styles.currentHead}>{t('openPosition.LiquidationFee_text')}:&nbsp;</span>
          <span className={styles.currentPrice}>
            {positionData?.liquidationFee?.toFixed(2)}&nbsp;
          </span>
          <span className={styles.currentUnit}>USDC</span>
        </div>
        <div className={styles.totelCommit}>
          <span className={styles.currentHead}>{t('openPosition.TotalCommitment_text')}:&nbsp;</span>
          <span className={styles.currentPrice}>
            {totalCommitment?.toFixed(2)}&nbsp;
          </span>
          <span className={styles.currentUnit}>USDC</span>
        </div>

        <div className={styles.leverage}>{t('openPosition.Leverage_text')}: {leverage}X</div>
        <div className={styles.rangeparent}>
          <input
            type="range"
            min={minLeverage}
            max={maxLeverage}
            step="0.5"
            className={styles.range}
            value={leverage}
            onChange={(evt) => {
              setLeverage(evt.target.value);
            }}
          />
          <div className={styles.rangeButtons}>
            <button className={styles.rangeButton}>{minLeverage}X</button>
            <button className={styles.rangeButton}>{leverage1}X</button>
            <button className={styles.rangeButton}>{leverage2}X</button>
            <button className={styles.rangeButton}>{maxLeverage}X</button>
          </div>
        </div>
        <div className={styles.checkBoxparent}>
          <div
            className={styles.checkBox}
            onClick={() => {
              setChecked(!checked);
            }}
          >
            {checkMark}
          </div>
          <span className={styles.checkBoxLabel}>{t("openPosition.TP_text")}</span>
        </div>
        <div className={checkCss}>
          <div className={styles.profitLoss}>
            <div className={styles.plCol}>
              <div className={styles.profitLossHeader}>{t("openPosition.TakeProfit_text")}</div>
              <input
                type="number"
                className={styles.profitLossinput}
                value={takeProfit}
                onChange={(evt) => {
                  const re = /^([0-9]*[.])?[0-9]+$/;
                  if (
                    (evt.target.value === "" || re.test(evt.target.value)) &&
                    Number(evt.target.value) >= 0
                    //Number(evt.target.value) <= 100
                  ) {
                    const val = evt.target.value
                      ? Number(evt.target.value).toString()
                      : "";
                    setTakeProfit(val);
                  }
                }}
              ></input>
            </div>
            <div className={styles.plCol}>
              <div className={styles.profitLossHeader}>{t("openPosition.StopLoss_text")}</div>
              <input
                type="number"
                className={styles.profitLossinput}
                value={stopLoss}
                onChange={(evt) => {
                  const re = /^([0-9]*[.])?[0-9]+$/;
                  if (
                    (evt.target.value === "" || re.test(evt.target.value)) &&
                    Number(evt.target.value) >= 0 &&
                    Number(evt.target.value) <= 100
                  ) {
                    const val = evt.target.value
                      ? Number(evt.target.value).toString()
                      : "";
                    setStopLoss(val);
                  }
                }}
              ></input>
            </div>
          </div>
        </div>
        <div className={styles.entryRow}>
          <div className={styles.entryText}>{t('openPosition.EntryPrice_text')}:</div>
          <div className={styles.entryNum}>
            {!!positionData?.entryPrice &&
              Number(positionData.entryPrice).toFixed(
                selectedPool.title === "USDT"
                  ? 4
                  : selectedPool.title === "ANCHOR"
                    ? 3
                    : 2
              )}
            {positionData?.entryPrice && "$"}
          </div>
        </div>
        <div className={styles.entryRow}>
          <div className={styles.entryText}>{t('openPosition.PriceImpact_text')}:</div>
          <div className={styles.entryNum}>{positionData?.impact}%</div>
        </div>
        <div className={styles.openButtonParrent}>
          <Button
            variant="open-position"
            onClick={openPositionHandler}
            disabled={inprogress || notHaveEnoughFunds || !totalCommitment}
          >
            <div className={styles.btnContent}>
              <span>{t('openPosition.OpenPosition_text')}</span>
              {inprogress && <img className={styles.loading} src={loading} alt="loading spinner" />}
            </div>
          </Button>
        </div>
        {notHaveEnoughFunds && (
          <div className={styles.error3}>
            {t("openPosition.ErrorMessages.noFunds")}
          </div>
        )}
      </div>
      {!checked && projectConfig.configs.tokenHold && <AdsSmall />}
    </div>
  );
}
export default OpenPosition;
