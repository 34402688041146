import { ReactComponent as CircleAddIcon } from 'assets/add_circle.svg';
import { ReactComponent as CircleDownIcon } from 'assets/circle-down-solid.svg';
import { ReactComponent as GearIcon } from 'assets/gear-solid.svg';
import { ReactComponent as RightLeftIcon } from 'assets/right-left-solid.svg';
import { ReactComponent as SwapIcon } from 'assets/swap.svg';
import classNames from 'classnames';
import Help from 'components/help/help';
import ImportPool from 'components/import-pool/ImportPool';
import ManageLiquidity from 'components/manage-liquidity/ManageLiquidity';
import useSwap from 'hooks/useSwap';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isNumeric } from 'utils/libs';
import ChangeSlippage from './change-slippage';
import SwapInput from './swap-input';
import styles from './swap.module.css';
import projectConfig from '_customization/project-config';
import Button from 'components/_shared/button/Button';

function Swap() {
  const { t } = useTranslation();
  const {
    getTokenBalance,
    getTokensList,
    isRouterApproved,
    approveRouter,
    getAmountOut,
    getAmountIn,
    exchangeToken,
    getOtherTokenValue,
    addLiquidity
  } = useSwap();
  const dispatch = useDispatch();
  const swapFromToken = useSelector((state) => state.swapFromToken);
  const swapToToken = useSelector((state) => state.swapToToken);
  const userAddress = useSelector((state) => state.userAddress);
  const web3 = useSelector((state) => state.web3);
  const [updateApprove, setUpdateApprove] = useState(true);
  const [fromAmount, setFromAmount] = useState('');
  const [toAmount, setToAmount] = useState('');
  const swap = 'Swap';
  const liquidity = 'Liquidity';
  const [action, setAction] = useState(swap);
  const [formTokenIsApproved, setFromTokenIsApproved] = useState(false);
  const [toTokenIsApproved, setToTokenIsApproved] = useState(false);
  const isApproved =
    (action === swap && formTokenIsApproved) ||
    (action === liquidity && formTokenIsApproved && toTokenIsApproved);
  const [fromTokenBlnc, setFromTokenBlnc] = useState();
  const [toTokenBlnc, setToTokenBlnc] = useState();
  const balanceUpdate = useSelector((state) => state.balanceUpdate);
  const [disable, setDisable] = useState(false);
  const [onHover, setOnHover] = useState(false);
  const [showSlippage, setShowSlippage] = useState(false);
  const LiquidityTabContents = {
    liquidity: 1,
    manageliquity: 2,
    importPool: 3
  };
  const [liquidityTabContent, setLiquidityTabContent] = useState(
    LiquidityTabContents.liquidity
  );
  //////
  const feeLevelUpgradeAmount = useSelector(
    (state) => state.feeLevelUpgradeAmount
  );
  const slippagePercentage = useSelector((state) => state.slippagePercentage);
  //////
  const tokenList = getTokensList().filter(
    (item) => !(action === liquidity && item === 'BNB')
  );
  
  /////
  function selectAction(action) {
    setAction(action);
    setLiquidityTabContent(LiquidityTabContents.liquidity);
    
    setFromAmount('');
    setToAmount('');
    setUpdateApprove(!updateApprove);
  }
  
  useEffect(() => {
    if (!swapFromToken || !swapToToken) {
      fromTonkenChange("USDC");
      toTonkenChange(projectConfig.tokenName);
      if (feeLevelUpgradeAmount) {
        onToAmountChange(feeLevelUpgradeAmount);
        dispatch({
          type: "feeLevelUpgradeAmountChange",
          feeLevelUpgradeAmount: null,
        });
      }
    }
  }, [swapFromToken, swapToToken]);
  
  useEffect(() => {
    setFromTokenIsApproved(false);
    setToTokenIsApproved(false);
    setFromTokenBlnc();
    setToTokenBlnc();
    if (userAddress && web3) {
      if (swapFromToken) {
        getTokenBalance(swapFromToken, false).then((res) => {
          if (res) setFromTokenBlnc(res);
        });
        isRouterApproved(swapFromToken).then((res) => {
          if (typeof res === 'boolean') setFromTokenIsApproved(res);
        });
      }
      if (swapToToken) {
        getTokenBalance(swapToToken, false).then((res) => {
          if (res) setToTokenBlnc(res);
        });
        isRouterApproved(swapToToken).then((res) => {
          if (typeof res === 'boolean') setToTokenIsApproved(res);
        });
      }
    }
  }, [
    swapFromToken,
    swapToToken,
    userAddress,
    web3,
    updateApprove,
    balanceUpdate
  ]);
  //////
  useEffect(() => {
    onFromAmountChange(fromAmount);
  }, [swapFromToken, swapToToken]);
  useEffect(() => {
    if (action === liquidity) {
      if (swapFromToken === 'BNB') fromTonkenChange(null);
      if (swapToToken === 'BNB') toTonkenChange(null);
    }
  }, [action]);
  
  //////
  async function onApprove(token) {
    if (userAddress && web3) {
      approveRouter(token).then((res) => {
        if (res.status === true)
          dispatch({
            type: 'showMessageChange',
            showMessage: true,
            txlink: res.txlink,
            messageTitle: t('transactionPopups.Approved_text')
          });
        setUpdateApprove(!updateApprove);
      });
    }
  }
  
  function onFromAmountChange(amount) {
    setFromAmount(amount);
    setToAmount('');
    if (userAddress && web3 && swapFromToken && swapToToken && +amount > 0) {
      if (action === swap)
        getAmountOut(amount, swapFromToken, swapToToken).then((res) => {
          if (res && isNumeric(res)) {
            setToAmount(+res);
          }
        });
      if (action === liquidity)
        getOtherTokenValue(swapFromToken, swapToToken, true, amount).then(
          (res) => {
            if (isNumeric(res)) setToAmount(+res);
          }
        );
    }
  }
  
  function onToAmountChange(amount) {
    setToAmount(amount);
    setFromAmount('');
    if (userAddress && web3 && swapFromToken && swapToToken && +amount > 0) {
      if (action === swap)
        getAmountIn(amount, swapFromToken, swapToToken).then((res) => {
          //console.log(res);
          if (res && isNumeric(res)) {
            setFromAmount(+res);
          }
        });
      if (action === liquidity)
        getOtherTokenValue(swapFromToken, swapToToken, false, amount).then(
          (res) => {
            if (isNumeric(res)) setFromAmount(+res);
          }
        );
    }
  }
  
  function onSwap() {
    if (
      userAddress &&
      web3 &&
      isApproved &&
      swapFromToken &&
      swapToToken &&
      +fromAmount > 0 &&
      +toAmount > 0
    ) {
      setDisable(true);
      exchangeToken(
        fromAmount,
        toAmount,
        swapFromToken,
        swapToToken,
        swapToToken
      ).then((res) => {
        if (res.status === true)
          dispatch({
            type: 'showMessageChange',
            showMessage: true,
            txlink: res.txlink,
            messageTitle: t('transactionPopups.Swapped_text')
          });
        setDisable(false);
        dispatch({ type: 'balanceUpdateChange' });
        //console.log(res);
      });
    }
  }
  
  function onLiquidate() {
    if (
      userAddress &&
      web3 &&
      isApproved &&
      swapFromToken &&
      swapToToken &&
      +fromAmount > 0 &&
      +toAmount > 0
    ) {
      setDisable(true);
      addLiquidity(swapFromToken, swapToToken, fromAmount, toAmount).then(
        (res) => {
          if (res.status === true)
            dispatch({
              type: 'showMessageChange',
              showMessage: true,
              txlink: res.txlink,
              messageTitle: t('transactionPopups.TransactionConfirmed_text')
            });
          setDisable(false);
          dispatch({ type: 'balanceUpdateChange' });
          // console.log(res);
        }
      );
    }
  }
  
  function fromTonkenChange(token) {
    dispatch({ type: 'swapFromTokenChange', swapFromToken: token });
  }
  
  function toTonkenChange(token) {
    dispatch({ type: 'swapToTokenChange', swapToToken: token });
  }
  
  function swapTokens() {
    dispatch({ type: 'swapTokens' });
  }
  
  function onManageLiquidity() {
    setAction(liquidity);
    setLiquidityTabContent(LiquidityTabContents.manageliquity);
  }
  
  function onImportPool() {
    setAction(liquidity);
    setLiquidityTabContent(LiquidityTabContents.importPool);
  }
  
  function onAddMore(tokenA, tokenB) {
    setAction(liquidity);
    setLiquidityTabContent(LiquidityTabContents.liquidity);
    fromTonkenChange(tokenA);
    toTonkenChange(tokenB);
  }
  
  return (
    <div className={styles.container}>
      <div className={styles.bg}>{t('swap.swap_text')}</div>
      <div className={styles.row1}>
        {t('swap.swap_text')}
        <Help
          shortMessage={t('swap.shortmessage_text')}
          longMessage={
            t('swap.longmessage_object.text1')
            + ' ' + projectConfig.tokenName + ' '
            + t('swap.longmessage_object.text2')
          }
        />
      </div>
      <div className={styles.row2}>
        <div className={styles.col21}>
          <div>{t('swap.exchange_text')}</div>
          <div className={styles.r3}>{t('swap.tradeTokens_text')}</div>
        </div>
        <div className={styles.tab}>
          <div className={styles.tabTitle}>
            <div
              onClick={() => selectAction(swap)}
              className={classNames(
                styles.tabItem,
                action === swap && styles.tabItemSelected
              )}
            >
              {t('swap.swaps_text')}
            </div>
            <div
              onClick={() => selectAction(liquidity)}
              className={classNames(
                styles.tabItem,
                action === liquidity && styles.tabItemSelected
              )}
            >
              {t('swap.liquidity_text')}
            </div>
          </div>
          <div
            className={classNames(
              styles.tabLine,
              action === liquidity && styles.tab2Selected
            )}
          ></div>
        </div>
      </div>
      {liquidityTabContent === LiquidityTabContents.manageliquity && (
        <ManageLiquidity onImportPool={onImportPool} onAddMore={onAddMore}/>
      )}
      {liquidityTabContent === LiquidityTabContents.importPool && (
        <ImportPool onBack={onManageLiquidity}/>
      )}
      {liquidityTabContent === LiquidityTabContents.liquidity && (
        <>
          <div className={classNames('noselect', styles.inputContainer)}>
            <div className={styles.row4}>
              <SwapInput
                deactiveToken={swapToToken}
                balance={fromTokenBlnc}
                label={action === swap ? t('swap.From_text') : ''}
                list={tokenList}
                selectedValue={swapFromToken}
                onSelected={fromTonkenChange}
                amount={fromAmount}
                onAmountChange={onFromAmountChange}
              />
            </div>
            <div className={styles.row5}>
              <span
                onClick={() => {
                  swapTokens();
                }}
                onMouseEnter={() => setOnHover(true)}
                onMouseLeave={() => setOnHover(false)}
              >
                {action === liquidity && (
                  <CircleAddIcon className={styles.circleDownIcon}/>
                )}
                {action === swap &&
                  (onHover ? (
                    <SwapIcon className={styles.circleDownIcon}/>
                  ) : (
                    <CircleDownIcon className={styles.circleDownIcon}/>
                  ))}
              </span>
            </div>
            <div className={styles.row6}>
              <SwapInput
                deactiveToken={swapFromToken}
                balance={toTokenBlnc}
                label={action === swap ? t('swap.to_text') : ''}
                list={tokenList}
                selectedValue={swapToToken}
                onSelected={toTonkenChange}
                amount={toAmount}
                onAmountChange={onToAmountChange}
              />
            </div>
          </div>
          <div className={styles.row7}>
            <div style={{ flex: 1 }}>{t('swap.price_text')}</div>
            <div>
              {toAmount > 0 && fromAmount > 0 && (
                <>
                  {' '}
                  {(toAmount / fromAmount)?.toFixed(toAmount / fromAmount > 0.01 ? 2 : 4)} &nbsp; {swapToToken} per {swapFromToken}
                </>
              )}
            </div>
            <RightLeftIcon className={styles.icon}/>
          </div>
          <div className={styles.row8}>
            <div style={{ flex: 1 }}>{t('swap.slippage_text')}</div>
            <div>{slippagePercentage}%</div>
            <GearIcon
              className={classNames(styles.icon, styles.link)}
              onClick={() => setShowSlippage(true)}
            />
            <ChangeSlippage
              showSlippage={showSlippage}
              onClose={() => setShowSlippage(false)}
            />
          </div>
          <div className={styles.row9}>
            {!formTokenIsApproved && (
              <Button
                onClick={() => onApprove(swapFromToken)}
              >
                {t('swap.approve_text')} {swapFromToken}
              </Button>
            )}
            {action === liquidity &&
              swapFromToken !== swapToToken &&
              !toTokenIsApproved && (
                <Button
                  onClick={() => onApprove(swapToToken)}
                >
                  {t('swap.approve_text')} {swapToToken}
                </Button>
              )}
            {isApproved && (
              <Button
                disabled={disable}
                onClick={action === swap ? onSwap : onLiquidate}
              >
                {action === swap ? 'Swap' : 'Create LP'}
              </Button>
            )}
          </div>
          <div className={styles.mglnkParent}>
            <div className={styles.mglnk} onClick={onManageLiquidity}>
              {t('swap.manageLiquidity_text')}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Swap;
