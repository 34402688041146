import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import pathNames from 'utils/path-names';
import styles from './Ads.module.css'
import projectConfig from '_customization/project-config'
import Button from 'components/_shared/button/Button'
import { useEffect, useState } from 'react'
import { handleSetLanguageStyle } from 'utils/shared-methods'

function Ads() {
  const { t } = useTranslation();
  const navigate = useNavigate();

    const [translatedDeStyle, setTranslatedDeStyle] = useState('');
  const [translatedTrStyle, setTranslatedTrStyle] = useState('');

  useEffect(() => {
      handleSetLanguageStyle("de", setTranslatedDeStyle);
      handleSetLanguageStyle("tr", setTranslatedTrStyle);
  }, [window.localStorage.getItem('i18nextLng')]);

  function onBuyAnchor() {
      navigate(pathNames.exchange);
  }
  return <div className={styles.container}>
      <div className={styles.title}>
          {t('openPosition.Saving_text')}
          <br />
          <span className={styles.titleColor}>{t('openPosition.60%_text')}</span>
          {" "}
          {t('openPosition.byHolding_text')}
          <br />
          {projectConfig.tokenName}
          {!translatedDeStyle.length ? " " : ""}
          {t('openPosition.token_text')}
      </div>
      <div className={styles.subTitle}>
          {projectConfig.tokenName}
          {!translatedDeStyle.length ? " " : ""}
          {t('openPosition.word_text')}
      </div>
      <Button variant="ads" onClick={onBuyAnchor}>
          {t('openPosition.BuyProjectToken_object.text1')}
          {" " + projectConfig.tokenName}
          {!translatedTrStyle.length ? " " : ""}
          {t('openPosition.BuyProjectToken_object.text2')}
      </Button>
  </div>
}
export default Ads;