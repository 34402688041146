import styles from "./help.module.css";
import { ReactComponent as HelpIcon } from "assets/help.svg";
import { useRef, useState } from "react";
import { ReactComponent as Xmark } from "assets/xmark.svg";
import classNames from "classnames";
import useOutsideDetector from "hooks/useOutsideDetector";

function Help({ shortMessage, longMessage, onLeft = false }) {
  const [showShortMessage, setShowShortMessage] = useState(false);
  const [showLongMessage, setShowLongMessage] = useState(false);
  const outRef = useRef(null);
  useOutsideDetector(outRef, () => {
    setShowLongMessage(false);
  });

  return (
    <div ref={outRef} className={styles.help}>
      <HelpIcon
        className={styles.helpIcon}
        onMouseEnter={() => {
          if (!showLongMessage) setShowShortMessage(true);
        }}
        onMouseLeave={() => setShowShortMessage(false)}
        onClick={(e) => {
          e.stopPropagation();
          setShowShortMessage(false);
          setShowLongMessage(!showLongMessage);
        }}
      />
      {showShortMessage && (
        <div
          className={classNames(
            styles.content,
            styles.short,
            onLeft && styles.left
          )}
          onClick={(e) => e.stopPropagation()}
        >
          {shortMessage}
        </div>
      )}
      {showLongMessage && (
        <div
          className={classNames(
            styles.content,
            styles.long,
            onLeft && styles.left
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <Xmark
            className={styles.xmark}
            onClick={() => setShowLongMessage(false)}
          />
          <div>{longMessage}</div>
        </div>
      )}
    </div>
  );
}

export default Help;
