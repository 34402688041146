import { useSelector } from "react-redux";
import useExecuteContract from "./useExecuteContract";
import { rpcURL } from "../constants/rpcs";
import {
  baseTokenAddress,
  swapPathCreatorAddress,
  marginAddress,
  pools,
  tokenToAddress,
} from "../constants/contracts";
import swapPathAbi from "../constants/ABI/swapPathCreator.json";
import marginAbi from "../constants/ABI/margin.json";
import erc20abi from "../constants/ABI/erc20.json";
import BigNumber from "bignumber.js";
import Web3 from "web3";
import { useState } from "react";
import projectConfig from '_customization/project-config'

BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_UP });

const usePosition = () => {
  const { callContract, sendContract } = useExecuteContract();
  const userAddress = useSelector((state) => state.userAddress);
  //const userAddress = '0x09b72d60dab7d3fdefbaca91d6d895fbc2418a42';
  const web3wallet = useSelector((state) => state.web3);
  const [swapContract, setSwapContract] = useState(null);
  const [liquidationBonus, setLiquidationBonus] = useState(null);
  const [liquidityBonusPercentage, setLiquidityBonusPercentage] = useState(null);

  const getWeb3 = () => {
    return web3wallet ? web3wallet : new Web3(rpcURL);
  };

  const getLiquidityBonus = async () => {
    if (liquidationBonus) return liquidationBonus;
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(marginAbi, marginAddress);
    const [_, _bonus] = await callContract({
      contract,
      functionName: "liquidityBonus",
    });
    const bonus = parseFloat(
        new BigNumber(_bonus.toString()).dividedBy(10 ** 18).toString()
    );
    if (bonus) setLiquidationBonus(bonus);
    return bonus;
  };

  const getLiquidityBonusPercentage = async () => {
    if (liquidityBonusPercentage) return liquidityBonusPercentage;
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(marginAbi, marginAddress);
    const [_, bonusPercentage] = await callContract({
      contract,
      functionName: "liquidityBonusPercentage",
    });
    if (liquidityBonusPercentage) setLiquidityBonusPercentage(bonusPercentage);
    return bonusPercentage;
  };

  const getAllPositions = async () => {
    return await getUserOpenPositions();
  };

  const getUserOpenPositions = async () => {
    const positionsData = await fetch(projectConfig.api.getUserOpenPositions + userAddress);
    const data = await positionsData.json();

    return data.result.reduce((acc, cur) => {
      acc.push(cur.positionId);
      return acc;
    }, []);
  };

  const getUserClosedPositions = async () => {
    const positionsData = await fetch(projectConfig.api.getUserClosedPositions + userAddress);
    const data = await positionsData.json();

    const positions = [];

    for (let i = 0; i < data.result.length; i++) {
      positions.push(await getClosedPositionData(data.result[i]));
    }

    return positions;
  };

  const getClosedShortPnL = (position, borrowInterest) => {
    const price = new BigNumber(position.scaledCloseRate).dividedBy(10 ** 18);
    const convertedPosition = new BigNumber(position.input).dividedBy(price);

    //set direction if loss
    const profitToken = new BigNumber(convertedPosition)
      .minus(BigNumber(position.owed))
      .minus(BigNumber(borrowInterest))
      .toString();

    const profitBaseToken = new BigNumber(profitToken).multipliedBy(price);

    return [
      new BigNumber(profitBaseToken)
        .dividedBy(BigNumber(position.commitment))
        .toString(),
      profitBaseToken,
    ];
  };

  const getClosedLongPnL = (position, borrowInterest) => {
    const convertedValue = new BigNumber(position.input)
      .multipliedBy(position.scaledCloseRate)
      .dividedBy(10 ** 18);

    const profitBaseToken = new BigNumber(convertedValue)
      .minus(position.owed)
      .minus(BigNumber(borrowInterest))
      .toString();

    return [
      BigNumber(profitBaseToken)
        .dividedBy(BigNumber(position.commitment))
        .toString(),
      profitBaseToken,
    ];
  };

  const getClosedPositionData = async (position) => {
    const startDate = new Date(position.startTimestamp * 1000);
    const startTimestamp = startDate.toISOString().substring(0, 16);

    const closeDate = new Date(position.block_timestamp.iso);
    const closeTimestamp = closeDate.toISOString().substring(0, 16);
    const positionId = position.positionId;
    const tokenAddress = Web3.utils.toChecksumAddress(position.token);
    const asset = pools.find((p) => p.tokenAddress === tokenAddress).title;
    let entryPrice = new BigNumber(position.input).dividedBy(position.owed);
    
    // const borrowInterest = new BigNumber(position.owed)
    //   .multipliedBy(loanTime)
    //   .multipliedBy(position.borrowInterest)
    //   .dividedBy(1000)
    //   .dividedBy(31536000);
    const borrowInterest = position.borrowInterest;

    if (!position.isShort) entryPrice = 1 / entryPrice;

    entryPrice = parseFloat(entryPrice).toFixed(3);

    const closingPrice = new BigNumber(position.scaledCloseRate)
      .dividedBy(1e18)
      .toFixed(3);
    const totalSize = new BigNumber(position.owed).dividedBy(1e18).toFixed(3);
    const leverage = new BigNumber(position.opendata[0].scaledLeverage)
      .dividedBy(1e18)
      .toFixed(3);

    const commitment = new BigNumber(position.commitment)
      .dividedBy(1e18)
      .toFixed(3);

    let pnl;
    let profit;
    if (position.isShort) {
      [pnl, profit] = getClosedShortPnL(position, borrowInterest);
    } else {
      [pnl, profit] = getClosedLongPnL(position, borrowInterest);
    }

    //convert pnl to percentage
    pnl = parseFloat((parseFloat(pnl) * 100).toFixed(3));
    profit = parseFloat(BigNumber(profit)
      .dividedBy(10 ** 18)
      .toFixed(3)
    );

    return {
      positionId,
      totalSize,
      commitment,
      position: position.isShort ? "Short" : "Long",
      asset,
      entryPrice,
      closingPrice,
      startTimestamp,
      closeTimestamp,
      leverage,
      borrowInterest,
      stopLossPercent: position.opendata[0].stopLossPercent,
      takeProfitPercent: position.opendata[0].takeProfitPercent,
      pnl,
      profit,
    };
  };

  const getUserBalance = async () => {
    if (!web3wallet) return { balance: 0, escrow: 0 };
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(marginAbi, marginAddress);

    const [errBalance, balanceWei] = await callContract({
      contract,
      functionName: "balanceOf",
      args: [userAddress],
    });

    const [errEscrow, escrowWei] = await callContract({
      contract,
      functionName: "escrow",
      args: [userAddress],
    });

    const balance = errBalance ? 0 : web3.utils.fromWei(balanceWei, 'ether');
    const escrow = errEscrow ? 0 : web3.utils.fromWei(escrowWei, 'ether');

    return { balance, escrow };
  };

  const getLiquidatablePositions = async () => {
    //for testing
    //return [positionIds[0], positionIds[1]];

    const web3 = new Web3(rpcURL);
    const contract = new web3.eth.Contract(marginAbi, marginAddress);
    const positions = [];

    const positionIds = await getUserOpenPositions();

    await Promise.all(
      positionIds.map(async (positionId) => {
        const [err, canLiquidate] = await callContract({
          contract,
          functionName: "canLiquidate",
          args: [positionId],
        });

        if (!err && canLiquidate) positions.push(positionId);
      })
    );

    return positions;
  };

  const getUserPositions = async () => {
    if (!web3wallet) return [];

    const web3 = getWeb3();
    const contract = new web3.eth.Contract(marginAbi, marginAddress);
    const userPositions = [];

    const positionIds = await getAllPositions();

    await Promise.all(
      positionIds.map(async (positionId) => {
        const [err, position] = await callContract({
          contract,
          functionName: "positionInfo",
          args: [positionId],
        });

        if (
          !err &&
          position.owner.toLowerCase() === userAddress.toLowerCase()
        ) {
          const positionData = await getPositionData(positionId);
          userPositions.push(positionData);
        }
      })
    );

    return userPositions;
  };

  const getSwapPathContract = () => {
    if (swapContract) return swapContract;
    const web3 = getWeb3();
    const swapPathContract = new web3.eth.Contract(
      swapPathAbi,
      swapPathCreatorAddress
    );
    setSwapContract(swapPathContract);
    return swapPathContract;
  };

  const getShortData = async (
    swapPathContract,
    position,
    commitmentWithoutLB,
    entryPrice
  ) => {
    const liquidationFee = await getLiquidityBonus();
    let liquidationBonus = Web3.utils.toWei(liquidationFee.toString(), 'ether');
    liquidationBonus = new BigNumber(liquidationBonus).plus(
        BigNumber(position.input).dividedBy(100)
    );

    const amt = new BigNumber(position.input)
      .plus(BigNumber(position.commitment))
      .minus(liquidationBonus)
      .integerValue(BigNumber.ROUND_DOWN);

    const amtFull = amt
        .toNumber()
        .toLocaleString("fullwide", { useGrouping: false });

    const [convertedValue, convertedPosition] = await Promise.all([
      callContract({
        contract: swapPathContract,
        functionName: "calculateConvertedValue",
        args: [baseTokenAddress, position.token, amtFull],
        getPromise: true,
      }),
      swapPathContract.methods
        .calculateConvertedValue(
          baseTokenAddress,
          position.token,
          position.input
        )
        .call(),
    ]);

    const canReturn = convertedValue.toString();
    const curPrice = new BigNumber(amt)
      .dividedBy(BigNumber(convertedValue))
      .toFixed(3);
    const interestInToken = new BigNumber(position.borrowInterest).dividedBy(
      curPrice
    );

    // ((amt + borrowInterest) / price ) = 1.1

    const liqPrice = new BigNumber(amt)
      .dividedBy(BigNumber(position.owed).plus(interestInToken))
      .dividedBy(11)
      .multipliedBy(10)
      .toString();

    //set direction if loss
    const profitToken = new BigNumber(convertedPosition)
      .minus(BigNumber(position.owed))
      .minus(BigNumber(position.borrowInterest))
      .abs()
      .toString();

    const profitBaseToken = await swapPathContract.methods
      .calculateConvertedValue(position.token, baseTokenAddress, profitToken)
      .call();

    let profitDirection = 1;
    if (
      BigNumber(convertedPosition).isLessThan(
        BigNumber(position.owed).plus(BigNumber(position.borrowInterest))
      )
    )
      profitDirection = -1;

    const pnl = new BigNumber(profitBaseToken)
      .dividedBy(BigNumber(commitmentWithoutLB))
      .toString();

    /*console.log(position.owed)
    console.log(entryPrice)
    console.log(commitmentWithoutLB)*/

    const leverage = new BigNumber(position.owed)
      .multipliedBy(entryPrice)
      .dividedBy(BigNumber(commitmentWithoutLB))
      .toFixed(2);

    return {
      leverage,
      pnl,
      profitBaseToken,
      profitDirection,
      canReturn,
      liqPrice,
      curPrice,
    };
  };

  const getLongData = async (
    swapPathContract,
    position,
    commitmentWithoutLB
  ) => {
    const convertedValue = await swapPathContract.methods
      .calculateConvertedValue(position.token, baseTokenAddress, position.input)
      .call();

    const liquidationFee = await getLiquidityBonus();
    let liquidationBonus = Web3.utils.toWei(liquidationFee.toString(), 'ether');

    liquidationBonus = new BigNumber(liquidationBonus).plus(
        BigNumber(position.owed).dividedBy(100)
    );

    const canReturn = new BigNumber(convertedValue)
      .plus(BigNumber(position.commitment))
      .minus(liquidationBonus)
      .toString();
    const curPrice = new BigNumber(convertedValue)
      .dividedBy(BigNumber(position.input))
      .toFixed(3);

    const canReturnForLiq = new BigNumber(position.owed)
      .plus(position.borrowInterest)
      .multipliedBy(11)
      .dividedBy(10);
    const convertedValueForLiq = canReturnForLiq
      .minus(BigNumber(position.commitment))
      .plus(liquidationBonus);
    const liqPrice = convertedValueForLiq.dividedBy(position.input).toString();

    const profitBaseToken = new BigNumber(convertedValue.toString())
      .minus(position.owed)
      .minus(BigNumber(position.borrowInterest))
      .abs()
      .toString();

    let profitDirection = 1;
    if (
      BigNumber(convertedValue).isLessThan(
        BigNumber(position.owed).plus(BigNumber(position.borrowInterest))
      )
    )
      profitDirection = -1;

    const pnl = new BigNumber(profitBaseToken)
      .dividedBy(BigNumber(commitmentWithoutLB))
      .toString();

    const leverage = new BigNumber(position.owed)
      .dividedBy(BigNumber(commitmentWithoutLB))
      .toFixed(2);

    return {
      leverage,
      pnl,
      profitBaseToken,
      profitDirection,
      canReturn,
      liqPrice,
      curPrice,
    };
  };

  const getPositionData = async (positionId) => {
    const web3 = new Web3(rpcURL);
    const contract = new web3.eth.Contract(marginAbi, marginAddress);
    const swapPathContract = getSwapPathContract();

    const calls = [
      callContract({
        contract,
        functionName: "positionInfo",
        args: [positionId],
        getPromise: true,
      }),
      callContract({
        contract,
        functionName: "calculateBorrowInterest",
        args: [positionId],
        getPromise: true,
      }),
    ];
    calls.push();

    const [position, borrowInterest] = await Promise.all(calls);

    if (position.owner === "0x0000000000000000000000000000000000000000") {
      return null;
    }

    position.borrowInterest = borrowInterest;

    const startTimestamp = new Date(position.startTimestamp * 1000)
      .toISOString()
      .substring(0, 16);

    position.positionId = positionId;
    const user =
      position.owner.substring(0, 4) + "..." + position.owner.substr(-4);

    const liquidationFee = await getLiquidityBonus();
    const liquidationBonusPercentage = await getLiquidityBonusPercentage();
    let liquidationBonus = Web3.utils.toWei(liquidationFee.toString(), 'ether');
    if (position.isShort) {
      liquidationBonus = new BigNumber(liquidationBonus).plus(
          BigNumber(position.input).multipliedBy(liquidationBonusPercentage).dividedBy(1000)
      );
    }
    else {
      liquidationBonus = new BigNumber(liquidationBonus).plus(
          BigNumber(position.owed).multipliedBy(liquidationBonusPercentage).dividedBy(1000)
      );
    }

    const asset = pools.find((p) => p.tokenAddress === position.token).title;

    const commitmentWithoutLB = new BigNumber(position.commitment)
      .minus(liquidationBonus.toString())
      .toString();

    let entryPrice = new BigNumber(position.input).dividedBy(position.owed);
    //entryPrice = (parseInt(position.input) * 1000) / 998 / parseInt(position.owed);
    if (!position.isShort) entryPrice = 1 / entryPrice;

    let positionalData;

    if (position.isShort) {
      positionalData = await getShortData(
        swapPathContract,
        position,
        commitmentWithoutLB,
        entryPrice
      );
    } else {
      positionalData = await getLongData(
        swapPathContract,
        position,
        commitmentWithoutLB
      );
    }

    //convert pnl to percentage
    const pnl =
      parseFloat(
        positionalData.profitDirection * parseFloat(positionalData.pnl) * 100
      ).toFixed(1);

    const canReturnRatio = new BigNumber(positionalData.canReturn)
      .dividedBy(BigNumber(position.owed).plus(position.borrowInterest))
      .toString();

    entryPrice = parseFloat(entryPrice).toFixed(3);
    const liqPrice = parseFloat(positionalData.liqPrice).toFixed(3);
    const curPrice = parseFloat(positionalData.curPrice).toFixed(3);

    const totalSize = new BigNumber(position.owed).dividedBy(1e18).toFixed(2);

    const commitment = new BigNumber(commitmentWithoutLB)
      .dividedBy(1e18)
      .toFixed(2);

    const profit = parseFloat(
        new BigNumber(positionalData.profitBaseToken)
            .dividedBy(10 ** 18)
            .multipliedBy(positionalData.profitDirection)
            .toFixed(2));

    return {
      positionId,
      totalSize,
      commitment,
      position: position.isShort ? "Short" : "Long",
      pnl,
      profit,
      asset,
      leverage: positionalData.leverage,
      entryPrice,
      curPrice,
      liqPrice,
      stopLossPercent: position.stopLossPercent,
      takeProfitPercent: position.takeProfitPercent,
      canReturnRatio,
      user,
      startTimestamp,
    };
  };

  const isApprovedMargin = async () => {
    if (!web3wallet) return null;
    const tokenContract = new web3wallet.eth.Contract(
      erc20abi,
      baseTokenAddress
    );

    const [, userAllowance] = await callContract({
      contract: tokenContract,
      functionName: "allowance",
      args: [userAddress, marginAddress],
    });

    return new BigNumber(1e23).isLessThan(BigNumber(userAllowance));
  };

  const liquidatePosition = async (positionId) => {
    if (!web3wallet) return null;
    const contract = new web3wallet.eth.Contract(marginAbi, marginAddress);
    return await sendContract(contract, {
      functionName: "runLiquidatePosition",
      arguments: [positionId, 0],
    });
  };

  //options = {isShort, token, totalSize, leverage, takeProfit, stopLoss}
  const openPosition = async (options) => {
    if (!web3wallet) return null;

    const contract = new web3wallet.eth.Contract(marginAbi, marginAddress);
    const method = options.isShort
      ? "openShortPositionWithSlTp"
      : "openLongPositionWithSlTp";

    const tokenName = options.token;
    const tokenAddress = tokenToAddress[tokenName];
    const takeProfit = options.takeProfit ?
        parseInt(parseFloat(options.takeProfit) * 100).toString()
        : "0";
    const stopLoss = options.stopLoss ?
        parseInt(parseFloat(options.stopLoss) * 100).toString()
        : "0";
    const leverage = web3wallet.utils.toWei(options.leverage.toString(), 'ether');
    const amount = web3wallet.utils.toWei(options.totalSize.toString(), 'ether');
    const args = [tokenAddress, amount, leverage, "0", takeProfit, stopLoss];
    return await sendContract(contract, {
      functionName: method,
      arguments: args,
    });
  };

  const depositMargin = async (amount) => {
    if (!web3wallet) return null;
    const contract = new web3wallet.eth.Contract(marginAbi, marginAddress);
    const amountWei = web3wallet.utils.toWei(amount.toString(), 'ether');
    return await sendContract(contract, {
      functionName: "deposit",
      arguments: [amountWei],
    });
  };

  const withdrawMargin = async (amount) => {
    if (!web3wallet) return null;
    const contract = new web3wallet.eth.Contract(marginAbi, marginAddress);
    const amountWei = web3wallet.utils.toWei(amount.toString(), 'ether');
    return await sendContract(contract, {
      functionName: "withdraw",
      arguments: [amountWei],
    });
  };

  const approveTokenMargin = async () => {
    if (!web3wallet) return null;
    const tokenContract = new web3wallet.eth.Contract(
      erc20abi,
      baseTokenAddress
    );
    return await sendContract(tokenContract, {
      functionName: "approve",
      arguments: [marginAddress, "99999999999999999999999999999"],
    });
  };

  const closePosition = async (positionId) => {
    if (!web3wallet) return null;
    const contract = new web3wallet.eth.Contract(marginAbi, marginAddress);
    return await sendContract(contract, {
      functionName: "closePosition",
      arguments: [positionId, 0],
    });
  };

  return {
    getSwapPathContract,
    getAllPositions,
    getUserPositions,
    getUserOpenPositions,
    getUserClosedPositions,
    getLiquidatablePositions,
    getPositionData,
    liquidatePosition,
    getUserBalance,
    isApprovedMargin,
    openPosition,
    depositMargin,
    withdrawMargin,
    approveTokenMargin,
    closePosition,
    getLiquidityBonus,
    getLiquidityBonusPercentage,
  };
};

export default usePosition;
