import styles from './margin.module.css'
import OpenPosition from 'components/open-position/open-position';
import Liquidate from 'components/liquidate/liquidate';
import Topup from 'components/topup/topup';
import Chart from 'components/chart/chart';
import Markets from 'components/markets/markets';
import { useMediaQuery } from 'react-responsive';

function Margin() {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 450px)' });
    if (isSmallScreen)
        return <div className={styles.container}>
            <OpenPosition />
            <div className={styles.lq}>
                <Liquidate />
            </div>
            <Topup />
            <div className={styles.row122}>
                <Chart />
            </div>
            <Markets />
        </div>
    return<>
    <div className={styles.container}>
        <div className={styles.row1}>
            <div className={'verticalSeparator'}></div>
            <div className={styles.col11}>
                <OpenPosition />
            </div>
            <div className={'verticalSeparator'}></div>
            <div className={styles.col12}>
                <div className={styles.row121}>
                    <div className={styles.col1211}>
                        <Liquidate />
                    </div>
                    <div className={'verticalSeparator'}></div>
                    <div className={styles.col1212}>
                        <Topup />
                    </div>
                </div>
                <div className={'horizontalSeparator'}></div>
                <div className={styles.row122}>
                    {
                        <Chart />
                    }
                </div>
            </div>
        </div>
        <div className={'horizontalSeparator'}></div>
        <div className={styles.marketContainer}>
            <Markets />
        </div>

    </div>;
    </> 
}
export default Margin;