import LiveChart from 'components/live-chart/live-chart';
import Markets from 'components/markets/markets';
import Swap from 'components/swap/swap';
import { useMediaQuery } from 'react-responsive';
import styles from './exchange.module.css';

function Exchange() {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 450px)' });
    if (isSmallScreen)
        return <div className={styles.container}>
            <Swap />
            {/*<Buy />*/}
            <LiveChart />
            <Markets />
        </div>
    return <div className={styles.container}>
        <div className={styles.row1}>
            <div className='verticalSeparator'></div>
            <div className={styles.col1}>
                <Swap />
            </div>
            {/*<div className='verticalSeparator'></div>
            <div className={styles.col1}>
                <Buy />
                </div>*/}
            <div className='verticalSeparator'></div>
            <div className={styles.col2}>
                <LiveChart />
            </div>
        </div>
        <div className='horizontalSeparator'></div>

        <div className={styles.marketContainer}>
            <Markets />
        </div>
    </div>

}

export default Exchange;