import styles from 'components/_shared/button/Button.module.css'

// variants: "connect-wallet", "approve", "liquidate", "open-position", "ads", "ads-small"
const Button = ({ disabled = false, onClick, variant = 'approve', children }) => {
    return (
        <button
            className={styles[variant]}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </button>
    );
}

export default Button;