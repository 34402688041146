import TokenIcon from 'components/tokenIcon/tokenIcon';
import styles from './ManageLiquidity.module.css'
import { ReactComponent as ExpandMoreIcon } from 'assets/expand-more.svg';
import { ReactComponent as ExpandLessIcon } from 'assets/expand-less.svg';
import { useEffect, useState } from 'react'
import classNames from 'classnames';
import useSwap from 'hooks/useSwap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'

function ManageLiquidity({ onImportPool, onAddMore }) {
    const { t } = useTranslation()
    const { getCachedUserLPs } = useSwap();
    const userAddress = useSelector((state) => state.userAddress);
    const [updated, setUpdated] = useState(false);
    const [list, setList] = useState([]);
    function onUpdate() {
        setUpdated(!updated);
    }
    useEffect(() => {
        setList([]);
        if (userAddress)
            getCachedUserLPs().then((res) => {
                if (Array.isArray(res)) {
                    //console.log(res);
                    setList(res);
                }
            });
    }, [userAddress, updated]);

    return <div className={styles.container}>
        <div className={styles.title}>{t("manageLiquidity.liquidity_text")}</div>
        <div className={styles.rows}>
            {list.map((data, ix) => <Row data={data} onUpdate={onUpdate} onAddMore={onAddMore} key={ix} />)}

        </div>
        <div className={styles.importpool}>
            {t("manageLiquidity.noPool_text")} <span onClick={onImportPool} className={styles.link}>{t("manageLiquidity.importPool_text")}</span>
        </div>
    </div>
}
function Row({ data, onUpdate, onAddMore }) {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const [isApprove, setIsApprove] = useState(true);
    const { removeLiquidity, isPairApproved, approvePair } = useSwap();
    function onCollapse() {
        setExpanded(false);
    }
    function onExpand() {
        setExpanded(true);
    }
    function onRemove() {
        removeLiquidity(data.tokenA, data.tokenB, data.amountWei, 0, 0).then(() => {
            onUpdate();
        })
    }
    function onApprove() {
        approvePair(data.pairAddress).then((res) => {
            if (res && res.status === true)
                setIsApprove(true);
        });
    }
    useEffect(() => {
        isPairApproved(data.pairAddress).then((res) => {
            if (typeof res === "boolean")
                setIsApprove(res);
        });
    }, []);

    return <div className={classNames(styles.row, 'noselect')}>
        <div className={classNames(styles.r1)}>
            <TokenIcon className={styles.tokenIcon} tokenName={data.tokenA} />
            <TokenIcon className={styles.tokenIcon} tokenName={data.tokenB} />
            <div className={styles.tokenName}>{data.tokenA}/{data.tokenB}</div>
            <div className={styles.fillSpace}></div>
            {expanded ? <ExpandLessIcon className={styles.expandIcon} onClick={onCollapse} /> :
                <ExpandMoreIcon className={styles.expandIcon} onClick={onExpand} />}
        </div>
        {expanded && <div className={styles.detail}>
            <div className={styles.r2}>
                <div>{t("manageLiquidity.pooled_text")} {data.tokenA}:</div>
                <div className={styles.fillSpace}></div>
                <div>{data.reserveA}</div>
                <TokenIcon className={styles.tokenIcon2} tokenName={data.tokenA} />
            </div>
            <div className={styles.r2}>
                <div>{t("manageLiquidity.pooled_text")} {data.tokenB}:</div>
                <div className={styles.fillSpace}></div>
                <div>{data.reserveB}</div>
                <TokenIcon className={styles.tokenIcon2} tokenName={data.tokenB} />
            </div>
            <div className={styles.r2}>
                <div>{t("manageLiquidity.poolTokens_text")}:</div>
                <div className={styles.fillSpace}></div>
                <div>{data.amount}</div>
            </div>
            <div className={styles.r2}>
                <div>{t("manageLiquidity.poolShare_text")}:</div>
                <div className={styles.fillSpace}></div>
                <div>{data.share}%</div>
            </div>
            <div className={styles.btns}>
                {isApprove === false &&
                    <button className={styles.btn} onClick={onApprove}>{t("manageLiquidity.approve_text")}</button>}
                {(isApprove === null || isApprove === true) &&
                    <button className={styles.btn} onClick={onRemove} disabled={isApprove === null}>
                        {t("manageLiquidity.remove_text")}
                    </button>
                }
                <button className={styles.btn} onClick={() => onAddMore(data.tokenA, data.tokenB)}>
                    {t("manageLiquidity.add_text")}
                </button>
            </div>
        </div>}
    </div>;
}
export default ManageLiquidity;