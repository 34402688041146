import styles from 'components/_shared/page-name/PageName.module.css'
import Help from 'components/help/help'

const PageName = ({ title, titleLong, text, shortMessage, longMessage, earning = false }) => {

    const returnStyle = (base) => {
        if (!earning) return base;

        return base + "-earning";
    }
    return <>
        <div className={styles[`${returnStyle("bg")}`]}>{title.toUpperCase()}</div>
        <div className={styles[`${returnStyle("section")}`]}>
            <div className={styles.row1}>
                <div className={styles.title}>{titleLong || title}</div>
                {Boolean(shortMessage) && <Help
                    shortMessage={shortMessage}
                    longMessage={longMessage}
                />}
            </div>
            <div className={styles.row2}>
                {text}
            </div>
        </div>
    </>
}

export default PageName;