import { pools } from "constants/contracts";

function reducer(
  state = {
    web3: null,
    userAddress: null,
    showMessage: false,
    txlink: null,
    messageTitle: null,
    feeLevelUpgradeAmount: null,
    liquidationFee: 2,
    openingFee: 2,
    interestRate: 10,
    slippagePercentage: 5,
    swapFromToken: null,
    swapToToken: null,
    showSidebar: true,
    selectedPool: pools[2],
    USDCBalance: null,
    positionsUpdate: true,
    balanceUpdate: true,
    periodicUpdate: false,
    languageSelected: 'English'
  },
  action
) {
  //currenctCurrency=prediction
  //num=currentround
  switch (action.type) {
    case "web3Change":
      return {
        ...state,
        web3: action.web3,
        userAddress: action.userAddress,
      };
    case "showMessageChange":
      return {
        ...state,
        showMessage: action.showMessage,
        txlink: action.txlink,
        messageTitle: action.messageTitle,
      };
    case "feeLevelUpgradeAmountChange":
      return {
        ...state,
        feeLevelUpgradeAmount: action.feeLevelUpgradeAmount,
      };
    case "liquidationFeeChange":
      return {
        ...state,
        liquidationFee: action.liquidationFee,
      };
    case "openingFeeChange":
      return {
        ...state,
        openingFee: action.openingFee,
      };
    case "interestRateChange":
      return {
        ...state,
        interestRate: action.interestRate,
      };
    case "slippagePercentageChange":
      return {
        ...state,
        slippagePercentage: action.slippagePercentage,
      };
    case "selectedPoolChange":
      return {
        ...state,
        selectedPool: action.selectedPool,
      };
    case "swapToTokenChange":
      return {
        ...state,
        swapToToken: action.swapToToken,
      };
    case "swapFromTokenChange":
      return {
        ...state,
        swapFromToken: action.swapFromToken,
      };
    case "swapTokens":
      return {
        ...state,
        swapToToken: state.swapFromToken,
        swapFromToken: state.swapToToken,
      };
    case "showSidebarChange":
      return {
        ...state,
        showSidebar: action.showSidebar,
      };
    case "USDCBalanceChange":
      return {
        ...state,
        USDCBalance: action.USDCBalance,
      };
    case "positionsUpdateChange":
      return {
        ...state,
        positionsUpdate: !state.positionsUpdate,
      };
    case "balanceUpdateChange":
      return {
        ...state,
        balanceUpdate: !state.balanceUpdate,
      };
    case "triggerPeriodicUpdate":
      return {
        ...state,
        periodicUpdate: !state.periodicUpdate,
      };
      case "languageChange":
      return {
        languageSelected: state.languageSelected,
      };
    default:
      return state;
  }
}

export default reducer;
