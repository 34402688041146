import useOutsideDetector from 'hooks/useOutsideDetector';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNumeric } from 'utils/libs';
import styles from './change-slippage.module.css'
import { useTranslation } from 'react-i18next'
function ChangeSlippage({ showSlippage, onClose }) {
    const { t } = useTranslation();
    const [amount, setAmount] = useState();
    const dispatch = useDispatch();
    const slippagePercentage = useSelector(state => state.slippagePercentage);
    const ref = useRef(null);
    useOutsideDetector(ref, () => { onClose() });

    useEffect(() => {
        setAmount(slippagePercentage);
    }, []);
    function setSlippage() {
        if (isNumeric(amount)) {
            dispatch({ type: "slippagePercentageChange", slippagePercentage: amount });
            onClose();
        }
    }
    return <>{showSlippage && <div ref={ref} className={styles.slippage}>
        <div>{t("swap.slippage_text")}</div>
        <input className={styles.slippageInput} type="number" value={amount} onChange={(evt) => { setAmount(evt.target.value); }} />
        <button className={styles.changeBtn} onClick={() => setSlippage()}>{t("swap.change_text")}</button>
    </div>}</>
}
export default ChangeSlippage;