import { ReactComponent as CircleAddIcon } from 'assets/add_circle.svg';
import { ReactComponent as AngleLeftIcon } from 'assets/angle-left.svg';
import { ReactComponent as ExpandLessIcon } from 'assets/expand-less.svg';
import { ReactComponent as ExpandMoreIcon } from 'assets/expand-more.svg';
import TokenIcon from 'components/tokenIcon/tokenIcon';
import styles from './ImportPool.module.css';

import classNames from 'classnames';
import useOutsideDetector from 'hooks/useOutsideDetector';
import useSwap from 'hooks/useSwap';
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

function ImportPool({ onBack }) {
    const { t } = useTranslation();
    const { saveCacheUserLP, getLPBalance } = useSwap();
    const [showError, setShowError] = useState(false);
    const [fromToken, setFromToken] = useState(null);
    const [toToken, setToToken] = useState(null);
    function swapTokens() {
        setToToken(fromToken);
        setFromToken(toToken);
    }
    function addLP() {
        setShowError(false);
        if (fromToken && toToken) {
            getLPBalance(fromToken, toToken).then((res) => {
                if (res !== null && res.amount !== null && Number(res.amount) > 0) {
                    saveCacheUserLP(fromToken, toToken);
                    onBack();
                } else {
                    setShowError(true);
                }
            })
        }
    }
    return <div className={classNames(styles.container, 'noselect')}>

        <AngleLeftIcon onClick={onBack} className={styles.back} />
        <div className={styles.title}>{t("importPool.importExistingPool_text")}</div>
        <SelectToken token={fromToken} deactiveToken={toToken} onSelected={setFromToken} />
        <div className={styles.r1}>
            <span onClick={swapTokens} >
                <CircleAddIcon className={styles.circleIcon} />
            </span>
        </div>
        <SelectToken token={toToken} deactiveToken={fromToken} onSelected={setToToken} />
        <div className={styles.lpLabel}>{t("importPool.lpInWallet_text")}</div>
        <div className={styles.lprow}>
            <TokenIcon className={styles.tokenIcon1} tokenName={fromToken} />
            <TokenIcon className={styles.tokenIcon2} tokenName={toToken} />
            <div className={styles.tkn2}>{fromToken !== null && toToken != null ? fromToken + '-' + toToken + 'LP' : ''}</div>
            <button className={styles.btnAddLp} onClick={addLP}>{t("importPool.addLp_text")}</button>
        </div>
        {showError && <div className={styles.error}>{t("importPool.noLiquidityInPool_text")}</div>}
    </div>
}
function SelectToken({ deactiveToken, token, onSelected }) {
    const { getTokensList } = useSwap();
    const tokenList = getTokensList();
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    useOutsideDetector(ref, () => { setShow(false) });
    function tokenSelected(item) {
        if (item !== deactiveToken) {
            setShow(false);
            onSelected(item);
        }
    }

    return <div className={styles.tokenRow}>
        <TokenIcon className={styles.tokenIcon} tokenName={token} />
        <div className={styles.fillSpace}></div>
        <div className={styles.tokenName} onClick={() => setShow(true)}>{token}</div>
        {show ? <ExpandLessIcon className={styles.expandIcon} /> : <ExpandMoreIcon onClick={() => setShow(true)} className={styles.expandIcon} />}
        {show && <div ref={ref} className={classNames(styles.options, 'noselect')}>
            {tokenList?.map((item, ix) => <div key={ix} className={styles.option} onClick={() => { tokenSelected(item) }}>
                <TokenIcon className={styles.tokenIcon} tokenName={item} />
                <div className={classNames(deactiveToken === item && styles.deactiveToken)}>{item}</div>
            </div>)}
        </div>}
    </div>
}
export default ImportPool;