
export const feeDiscountLevels = [
{ tier: 1, balance: 25000, discount: 2 },
{ tier: 2, balance: 100000, discount: 5 },
{ tier: 3, balance: 250000, discount: 10 },
{ tier: 4, balance: 500000, discount: 15 },
{ tier: 5, balance: 1000000, discount: 20 },
{ tier: 6, balance: 2500000, discount: 25 },
{ tier: 7, balance: 5000000, discount: 30 },
{ tier: 8, balance: 7500000, discount: 35 },
{ tier: 9, balance: 10000000, discount: 40 },
{ tier: 10, balance: 15000000, discount: 45 },
{ tier: 11, balance: 25000000, discount: 50 },
{ tier: 12, balance: 50000000, discount: 60 }
];

export function calcLevel(anchorBalance) {
    var currentTier = 0;
    for (let ix = 0; ix < feeDiscountLevels.length; ix++) {
        if (anchorBalance >= feeDiscountLevels[ix].balance)
            currentTier = feeDiscountLevels[ix].tier;
    }
    return currentTier;
}  