import { ReactComponent as ArrowDownIcon } from "assets/arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "assets/arrow-up.svg";
import classnames from "classnames";
import TokenIcon from "components/tokenIcon/tokenIcon";
import usePool from "hooks/usePool";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./select-token.module.css";

function SelectToken() {
  const { getAllPools } = usePool();
  const dispatch = useDispatch();

  const selectedPool = useSelector((state) => state.selectedPool);
  const pools = getAllPools().filter(
    (pool) =>
      pool.title !== "USDC" &&
      pool.title !== "ANCHOR" &&
      pool.title !== selectedPool?.title
  );
  const [open, setOpen] = useState(false);
  function onItemSelected(item) {
    dispatch({ type: "selectedPoolChange", selectedPool: item });
    setOpen(false);
  }
  return (
    <div className={classnames(styles.container, styles.noselect)}>
      <div
        className={classnames(styles.row, styles.selected)}
        onClick={() => setOpen(!open)}
      >
        <div className={styles.images}>
          <TokenIcon
            className={styles.image1}
            tokenName={selectedPool?.title}
          />
          <TokenIcon className={styles.image2} tokenName="USDC" />
        </div>
        <div>{selectedPool?.title}/USDC</div>
        {open ? (
          <ArrowUpIcon className={styles.arrow} />
        ) : (
          <ArrowDownIcon className={styles.arrow} />
        )}
      </div>
      {open && (
        <div className={classnames(styles.items)}>
          {pools.map((pool, ix) => (
            <div
              key={ix}
              className={classnames(styles.row, styles.item)}
              onClick={() => onItemSelected(pool)}
            >
              <div className={styles.images}>
                <TokenIcon className={styles.image1} tokenName={pool.title} />
                <TokenIcon className={styles.image2} tokenName="USDC" />
              </div>
              <div>{pool.title}/USDC</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
export default SelectToken;
