import Help from "components/help/help";
import usePosition from "hooks/usePosition";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./liquidate.module.css";
import { useTranslation } from 'react-i18next'
import Button from 'components/_shared/button/Button'

function Liquidate() {
  const { t } = useTranslation();
  const { getLiquidatablePositions, liquidatePosition } = usePosition();
  const [liquidateAddresses, setLiquidateAddresses] = useState([]);
  const [updateLiquidate, setUpdateLiquidate] = useState(false);
  const selectedPool = useSelector((state) => state.selectedPool);
  const dispatch = useDispatch();
  useEffect(() => {
    setLiquidateAddresses([]);
    getLiquidatablePositions().then((res) => {
      if (Array.isArray(res)) {
        setLiquidateAddresses(res);
      }
    });
  }, [updateLiquidate, selectedPool]);
  function onLiquidate(address, setConfirming) {
    setConfirming(true);
    liquidatePosition(address)
      .then((res) => {
        setConfirming(false);
        if (res.status === true)
          dispatch({
            type: "showMessageChange",
            showMessage: true,
            txlink: res.txlink,
            messageTitle: t("transactionPopups.TransactionConfirmed_text"),
          });
        setUpdateLiquidate(!updateLiquidate);
      })
      .catch(() => {
        setConfirming(false);
        setUpdateLiquidate(!updateLiquidate);
      });
  }
  return (
    <div className={styles.box}>
      <div className={styles.row1}>
          {t("liquidate.LIQUIDATEPOSITIONS_text")}
          <Help
          shortMessage={t("liquidate.shortmessage_text")}
          longMessage={t("liquidate.longmessage_text")}
          />
      </div>
      <div className={styles.total}>
        {liquidateAddresses.map((address, i) => (
          <Row key={i} address={address} onLiquidate={onLiquidate} />
        ))}
      </div>
    </div>
  );
}
function Row({ address, onLiquidate }) {
  const { t } = useTranslation();
  const [liquidationBonus, setLiquidationBonus] = useState(null);
  const [confirming, setConfirming] = useState(false);
  const { getLiquidityBonus } = usePosition();

  useEffect(() => {
      const load = async () => {
          const bonus = await getLiquidityBonus();
          setLiquidationBonus(bonus);
      };

      load();
  }, []);

  return (
    <div className={styles.internalBox}>
      Liquid Position {(address?.substring(0, 4) ?? "") + "..."} earn{" "}
      {liquidationBonus} USDC
        <Button
            variant="liquidate"
            disabled={confirming}
            onClick={() => onLiquidate(address, setConfirming)}
        >
            {t("liquidate.liquidate_text")}
        </Button>
    </div>
  );
}
export default Liquidate;
