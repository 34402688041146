import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'components/app/App';
import reportWebVitals from './reportWebVitals';
import store from "Redux/Store.js"
import { Provider } from "react-redux";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";
import HttpBackend from "i18next-http-backend";

// ** Important! Styles imports
import './index.css';
import '_customization/project-styles.module.css';
import projectConfig from '_customization/project-config'

// const apiKey = "-YSqoJES83eeUgVHEAaICg";
// const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",

    ns: ["default"],
    defaultNS: "default",

    supportedLngs: ["en", "de", "tr"],

    backend: {
      loadPath: "/assets/langs/{{lng}}/translation.json",
    },
  });

// ** ProjectName modification
document.title = projectConfig.appName

// ** ProjectDescription modification
document.querySelector('#description-placeholder').setAttribute('content', projectConfig.appName);

// ** Manifest Change
const ConfigurableManifest = {
    "short_name": projectConfig.appName,
    "name": projectConfig.appName + " App",
    "icons": [
        {
            "src": "favicon.ico",
            "sizes": "64x64 32x32 24x24 16x16",
            "type": "image/x-icon"
        }
    ],
    "start_url": ".",
    "display": "standalone",
    "theme_color": "#000000",
    "background_color": "#ffffff"
}
const stringManifest = JSON.stringify(ConfigurableManifest);
const blob = new Blob([stringManifest], {type: 'application/json'});
const manifestURL = URL.createObjectURL(blob);
document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
