import useStats from 'hooks/useStats';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MarketsHeader from './markets-header';
import MarketsTable from './markets-table';
function Markets() {
    const { getMarketsData } = useStats();
    const [marketsData, setMarketsData] = useState();
    const selectedPool = useSelector((state) => state.selectedPool);
    useEffect(() => {
        setMarketsData();
        getMarketsData().then((res) => {
            if (res) {
                setMarketsData(res);
            }
        });
    }, [selectedPool]);
    return <>
        <MarketsHeader marketsData={marketsData} />
        <MarketsTable pools={marketsData ? marketsData.marketPools : []} />
    </>
}

export default Markets;