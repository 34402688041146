import { swapTokens } from "../constants/contracts";
import projectConfig from '_customization/project-config'

const usePrice = () => {
  const getPriceChartData = async (tokenA, tokenB, days = "1") => {
    try {
      const [resultA, resultB] = await Promise.all([
        tokenA === "ANCHOR" ? getAnchorChart(days) : getBinanceChart(tokenA, days),
        tokenB === "ANCHOR" ? getAnchorChart(days) : getBinanceChart(tokenB, days),
      ]);
      
      const tokenAData = mapChart(tokenA, resultA);
      const tokenBData = mapChart(tokenB, resultB);
      
      let prices = [];
      
      const length = tokenAData.length < tokenBData.length ? tokenAData.length : tokenBData.length;
      for (let i = 0; i < length; i++) {
        const priceA = tokenAData[i][1];
        const priceB = tokenBData[i][1];
        const timestamp = tokenAData ? tokenAData[i][0] : tokenBData[i][0];
        prices[i] =
          priceB !== 0
            ? [timestamp, parseFloat(priceA) / parseFloat(priceB)]
            : 0;
      }
      
      return prices;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  
  const getBinanceChart = async (token, days) => {
    const binanceId = swapTokens[token].binanceId;
    const apiCode = binanceId !== "stable" ? binanceId : "USDCUSDT";
    
    const interval = intervalToBinance(days);
    const startTime = Math.ceil(
      new Date(Date.now() - 86400000 * parseInt(days))
    );
    
    const responce = await fetch(
      `https://api.binance.com/api/v3/klines?symbol=${apiCode}&interval=${interval}&startTime=${startTime}&limit=1000`
    );
    
    if (!responce.ok) return null;
    
    return await responce.json();
  };
  
  const getAnchorChart = async (days) => {
    const interval = intervalToAnchor(days);
    
    const response = await fetch(
      projectConfig.api.getCurrentTokenChart + interval
    );
    
    if (!response.ok) return null;
    
    const result = await response.json();
    return result.info.map((entry) => ({
      timestamp: parseInt(entry.timestamp),
      price: parseFloat(entry.price),
    }));
  };
  
  const mapChart = (token, data) => {
    let result;
    if (token === "ANCHOR") {
      result = data.map((entry) => [entry.timestamp, entry.price]);
    } else if (token === "USDC" || token === "USDT") {
      result = data.map((entry) => [entry[0], 1 / entry[4]]);
    } else {
      result = data.map((entry) => [entry[0], entry[4]]);
    }
    
    return result;
  };
  
  const getPairPriceChange = async (tokenA, tokenB) => {
    if (tokenA === "ANCHOR" || tokenB === "ANCHOR") {
      return await getPairPriceChangeGECKO(tokenA, tokenB);
    }
    return await getPairPriceChangeBINANCE(tokenA, tokenB);
  };
  
  const getPairPriceChangeGECKO = async (tokenA, tokenB) => {
    const inverted = tokenA !== "ANCHOR";
    const anchorPrice = await getAnchorChart("1");
    const other = tokenA === "ANCHOR" ? tokenB : tokenA;
    const otherId = swapTokens[other].binanceId;
    const otherAapiCode = otherId !== "stable" ? otherId : "USDCUSDT";
    const tokenAprice = await fetch(
      `https://api.binance.com/api/v3/ticker/24hr?symbol=${otherAapiCode}`
    ).then((r) => r.json());
    
    const otherOpenPrice = otherId !== "stable" ? tokenAprice.openPrice : 1;
    const otherLastPrice = otherId !== "stable" ? tokenAprice.lastPrice : 1;
    const anchorOpenPrice = anchorPrice.at(0).price;
    const anchorLastPrice = anchorPrice.at(-1).price;
    
    const oldPrice = anchorOpenPrice / parseFloat(otherOpenPrice);
    const newPrice = anchorLastPrice / parseFloat(otherLastPrice);
    const priceChange = !inverted ? oldPrice - newPrice : newPrice - oldPrice;
    const changePercentage = (100 * priceChange) / oldPrice;
    
    return { priceChange, changePercentage };
  };
  
  const getPairPriceChangeBINANCE = async (tokenA, tokenB) => {
    const tokenAid = swapTokens[tokenA].binanceId;
    const tokenBid = swapTokens[tokenB].binanceId;
    const tokenAapiCode = tokenAid !== "stable" ? tokenAid : "USDCUSDT";
    const tokenBapiCode = tokenBid !== "stable" ? tokenBid : "USDCUSDT";
    try {
      const [dataA, dataB] = await Promise.all([
        fetch(
          `https://api.binance.com/api/v3/ticker/24hr?symbol=${tokenAapiCode}`
        ),
        fetch(
          `https://api.binance.com/api/v3/ticker/24hr?symbol=${tokenBapiCode}`
        ),
      ]);
      const [resultA, resultB] = await Promise.all([
        dataA.json(),
        dataB.json(),
      ]);
      
      const priceAopen = tokenAid !== "stable" ? resultA.openPrice : 1;
      const priceBopen = tokenBid !== "stable" ? resultB.openPrice : 1;
      const priceAclose = tokenAid !== "stable" ? resultA.lastPrice : 1;
      const priceBclose = tokenBid !== "stable" ? resultB.lastPrice : 1;
      
      const priceOld = parseFloat(priceAopen) / parseFloat(priceBopen);
      const priceNew = parseFloat(priceAclose) / parseFloat(priceBclose);
      
      const priceChange = priceNew - priceOld;
      const changePercentage = (100 * priceChange) / priceOld;
      
      return { priceChange, changePercentage };
    } catch (err) {
      return null;
    }
  };
  
  const getPriceChange = async (tokenA) => {
    if (tokenA === "ANCHOR") {
      return await getPriceChangeAnchor();
    }
    return await getPriceChangeBINANCE(tokenA);
  };
  
  const getCurrentAnchorPrice = async () => {
    const data = await fetch(projectConfig.api.getTokenPrice + projectConfig.tokenName);
    return await data.json();
  };
  
  const getPriceChangeBINANCE = async (tokenA) => {
    const tokenAid = swapTokens[tokenA].binanceId;
    if (tokenAid === "stable")
      return { currentPrice: 1, priceChange: 0, changePercentage: 0 };
    try {
      const data = await fetch(
        `https://api.binance.com/api/v3/ticker/24hr?symbol=${tokenAid}`
      );
      const priceData = await data.json();
      if (!priceData.hasOwnProperty("priceChangePercent")) return 0;
      
      const changePercentage = parseFloat(priceData.priceChangePercent);
      const currentPrice = parseFloat(priceData.lastPrice);
      const priceChange = parseFloat(priceData.priceChange);
      
      return { currentPrice, priceChange, changePercentage };
    } catch {
      return 0;
    }
  };
  
  const getPriceChangeAnchor = async () => {
    const weekData = await getAnchorChart("7");
    
    const prevPrice = weekData[weekData.length - 2].price;
    const currentPrice = weekData[weekData.length - 1].price;
    
    const difference = currentPrice - prevPrice;
    
    return {
      priceChange: difference,
      changePercentage: (100 * difference) / prevPrice,
      currentPrice: await getCurrentAnchorPrice(),
    };
  };
  
  return { getPriceChartData, getPairPriceChange, getPriceChange };
};

export default usePrice;

const intervalToBinance = (interval) => {
  let result = "1h";
  if (interval.toString() === "7") {
    result = "1d";
  } else if (interval.toString() === "30") {
    result = "1d";
  } else if (interval.toString() === "365") {
    result = "1M";
  }
  
  return result;
};

const intervalToAnchor = (interval) => {
  let result = "day";
  if (interval.toString() === "7") {
    result = "week";
  } else if (interval.toString() === "30") {
    result = "month";
  } else if (interval.toString() === "365") {
    result = "year";
  }
  
  return result;
};