import { EthereumProvider } from '@walletconnect/ethereum-provider';

const { Web3 } = require('web3');

const chainData = {
  0: {
    'targetChainId': '0x38',
    'chainId': '56',
    'targetRpc': 'https://bsc-dataseed.binance.org',
    'rpcOptions': ['https://bsc-dataseed3.defibit.io', 'https://bsc-dataseed.binance.org', 'https://bsc-dataseed4.binance.org'],
    'targetChainName': 'BNB Smart Chain Mainnet',
    'nativeCurrency': {
      'name': 'BNB',
      'symbol': 'BNB',
      'decimals': 18
    }
  },
  10: {
    'targetChainId': '0x61',
    'chainId': '97',
    'targetRpc': 'https://data-seed-prebsc-2-s3.binance.org:8545/',
    'targetChainName': 'bsctestnet',
    'nativeCurrency': {
      'name': 'BNB',
      'symbol': 'BNB',
      'decimals': 18
    }
  },
  1: {
    'targetChainId': '0x89',
    'chainId': '137',
    'targetRpc': 'https://polygon-rpc.com/',
    'targetChainName': 'Polygon Mainnet',
    'nativeCurrency': {
      'name': 'MATIC',
      'symbol': 'MATIC',
      'decimals': 18
    }
  },
  11: {
    'targetChainId': '0x13881',
    'chainId': '80001',
    'targetRpc': 'https://matic-mumbai.chainstacklabs.com',
    'targetChainName': 'Polygon Testnet',
    'nativeCurrency': {
      'name': 'MATIC',
      'symbol': 'MATIC',
      'decimals': 18
    }
  }
};

export const tryReconnect = async (network) => {
  
  if (network < 0) return null;
  const walletName = localStorage['wallet'];
  let connection = null;
  
  if (process.env.REACT_APP_TEST === 'true')
    //console.log('tryReconnect', walletName + ' on network: '+ network);
    
    if (!walletName) {
      if (window.ethereum && window.ethereum.isMetaMask === true) {
        // var pending=localStorage['pending'];
        //if(!pending || pending=="false")
        connection = await connectMetamask(network);
      } else if (window.ethereum)
        connection = connectTrustWallet();
    }
  
  if (walletName === 'metamask') {
    
    connection = await connectMetamask(network);
  } else if (walletName === 'walletconnect') {
    connection = await connectWalletConnect(network);
  } else if (walletName === 'coin98') {
    connection = await connectCoin98(network);
  } else if (walletName === 'binanse') {
    connection = await connectBinanse(network);
  } else if (walletName === 'mathwallet') {
    connection = await connectMathWallet(network);
  } else if (walletName === 'trustwallet') {
    connection = await connectTrustWallet(network);
  }
  
  return connection;
};

export const onDisconnect = () => {
  localStorage['wallet'] = '';
};

export const connectBinanse = async (_network, forceAccountConnect = false) => {
  
  var acounts = null;
  var error = false;
  var web3 = null;
  
  let network = _network;
  if (process.env.REACT_APP_TEST === 'true') network += 10;
  
  var errorMessage = '';
  if (_network === 0) {
    if (window.BinanceChain) {
      
      try {
        if (forceAccountConnect) {
          await window.BinanceChain.request({
            method: 'wallet_requestPermissions',
            params: [{
              eth_accounts: {}
            }]
          });
        }
        var currentNetwork = await window.BinanceChain.request({ method: 'eth_chainId' });
        
        if (currentNetwork !== chainData[network].targetChainId) {
          error = true;
          errorMessage = 'incorrectNetwork';
        } else {
          acounts = await window.BinanceChain.request({ method: 'eth_requestAccounts' });
          web3 = new Web3(window.BinanceChain);
        }
        
      } catch (requestError) {
        
        error = true;
        errorMessage = requestError.message;
        
      }
      
    } else {
      error = true;
      errorMessage = 'noprovider';
      
    }
  } else {
    error = true;
    errorMessage = 'NetworkNotSupported';
  }
  
  if (error === false) {
    web3 = new Web3(window.BinanceChain);
    localStorage['wallet'] = 'binanse';
  }
  var finalAcc = null;
  if (acounts !== null)
    finalAcc = acounts[0];
  
  return {
    'error': error,
    'web3': web3,
    'errorMessage': errorMessage,
    'acounts': finalAcc
  };
};

export const connectWalletConnect = async () => {
  //console.log("via"+"wallet")
  let accounts;
  let error = false;
  let web3;
  let errorMessage = '';
  
  //  Create WalletConnect Provider
  const provider = await EthereumProvider.init({
    projectId: 'e0e2e82655b13fef6cbdda6e41aa7c12',
    chains: [56],
    /*rpcMap: {
      56: 'https://bsc-dataseed.binance.org/',
      97: 'https://data-seed-prebsc-2-s3.binance.org:8545/'
    }*/
  });
  
  //  Enable session (triggers QR Code modal)
  await provider.enable();
  
  web3 = new Web3(provider);
  accounts = await web3.eth.getAccounts();
  
  if (!error) localStorage['wallet'] = 'walletconnect';
  
  return {
    'error': error,
    'web3': web3,
    'errorMessage': errorMessage,
    'acounts': accounts[0]
  };
};

export const connectMetamask = async (_network, forceAccountConnect = false) => {
  //console.log("via"+"meta")
  var acounts = null;
  var error = false;
  var web3 = null;
  let network = _network;
  if (process.env.REACT_APP_TEST === 'true') network += 10;
  
  var errorMessage = '';
  if (window.ethereum) {
    
    try {
      if (forceAccountConnect) {
        await window.ethereum.request({
          method: 'wallet_requestPermissions',
          params: [{
            eth_accounts: {}
          }]
        });
      }
      
      // localStorage['pending'] = 'true';
      acounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      // localStorage["pending"]="false"
      
      try {
        
        // localStorage['pending'] = 'true';
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chainData[network].targetChainId }]
        });
        //  localStorage['pending'] = 'false';
        
      } catch (switchError) {
        //  localStorage['pending'] = 'false';   
        
        if (switchError.code === 4902) {
          try {
            //  localStorage['pending'] = 'true';   
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: chainData[network].targetChainId,
                rpcUrls: [chainData[network].targetRpc],
                chainName: chainData[network].targetChainName
              }]
            });
            //  localStorage['pending'] = 'false';
            
          } catch (addError) {
            //  localStorage['pending'] = 'false';   
            
            error = true;
            errorMessage = addError.message;
          }
        } else {
          
          error = true;
          errorMessage = switchError.message;
          
        }
      }
      web3 = new Web3(window.ethereum);
      
    } catch (requestError) {
      // localStorage['pending'] = 'false';
      
      error = true;
      errorMessage = requestError.message;
      
    }
    
  } else {
    error = true;
    errorMessage = 'noprovider';
    
  }
  
  if (error === false) {
    
    web3 = new Web3(window.ethereum);
    localStorage['wallet'] = 'metamask';
  }
  var finalAcc = null;
  if (acounts !== null)
    finalAcc = acounts[0];
  
  return {
    'error': error,
    'web3': web3,
    'errorMessage': errorMessage,
    'acounts': finalAcc
  };
};

export const connectCoin98 = async (_network) => {
  //console.log("via"+"coin")
  
  var acounts = null;
  var error = false;
  var web3 = null;
  let network = _network;
  if (process.env.REACT_APP_TEST === 'true') network += 10;
  var provider = window.ethereum;
  if (_network === 0) {
    provider = window.BinanceChain;
    
  }
  
  var errorMessage = '';
  if (provider) {
    
    try {
      // if(forceAccountConnect)
      acounts = await provider.request({ method: 'eth_requestAccounts' });
      
      var currentNetwork = await provider.request({ method: 'eth_chainId' });
      
      if (currentNetwork !== chainData[network].targetChainId) {
        error = true;
        errorMessage = 'incorrectNetwork';
      } else {
        if (acounts.length !== 0) {
          web3 = new Web3(provider);
        } else {
          error = true;
          errorMessage = 'nowallet';
        }
        
      }
      
    } catch (requestError) {
      
      error = true;
      errorMessage = requestError.message;
      
    }
    
  } else {
    error = true;
    errorMessage = 'noprovider';
    
  }
  
  if (error === false) {
    web3 = new Web3(provider);
    localStorage['wallet'] = 'coin98';
  }
  var finalAcc = null;
  if (acounts !== null && acounts.length !== 0)
    finalAcc = acounts[0];
  
  return {
    'error': error,
    'web3': web3,
    'errorMessage': errorMessage,
    'acounts': finalAcc
  };
};
export const connectMathWallet = async (_network) => {
  //console.log("via"+"math")
  
  var acounts = null;
  
  var error = false;
  var web3 = null;
  let network = _network;
  
  if (process.env.REACT_APP_TEST === 'true') network += 10;
  
  var errorMessage = '';
  if (window.ethereum) {
    
    try {
      
      var currentNetwork = await window.ethereum.request({ method: 'eth_chainId' });
      
      if (currentNetwork !== chainData[network].targetChainId) {
        error = true;
        errorMessage = 'incorrectNetwork';
      } else {
        
        acounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        web3 = new Web3(window.ethereum);
      }
      
    } catch (requestError) {
      
      error = true;
      errorMessage = requestError.message;
      
    }
    
  } else {
    error = true;
    errorMessage = 'noprovider';
    
  }
  
  if (error === false) {
    web3 = new Web3(window.ethereum);
    localStorage['wallet'] = 'mathwallet';
  }
  var finalAcc = null;
  if (acounts !== null)
    finalAcc = acounts[0];
  
  return {
    'error': error,
    'web3': web3,
    'errorMessage': errorMessage,
    'acounts': finalAcc
  };
};

export const connectTrustWallet = async (_network) => {
  //console.log("via"+"trust")
  
  var acounts = null;
  
  var error = false;
  var web3 = null;
  let network = _network;
  
  if (process.env.REACT_APP_TEST === 'true') network += 10;
  
  var errorMessage = '';
  if (window.ethereum) {
    
    try {
      
      var currentNetwork = await window.ethereum.request({ method: 'eth_chainId' });
      
      if (currentNetwork !== chainData[network].targetChainId) {
        error = true;
        errorMessage = 'incorrectNetwork';
      } else {
        
        acounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        web3 = new Web3(window.ethereum);
      }
      
    } catch (requestError) {
      
      error = true;
      errorMessage = requestError.message;
      
    }
    
  } else {
    error = true;
    errorMessage = 'noprovider';
    
  }
  
  if (error === false) {
    web3 = new Web3(window.ethereum);
    
  }
  var finalAcc = null;
  if (acounts !== null)
    finalAcc = acounts[0];
  
  return {
    'error': error,
    'web3': web3,
    'errorMessage': errorMessage,
    'acounts': finalAcc
  };
};


