import React from "react";
import styles from "./connectModal.module.css";
import meta from "assets/metamask.png";
import wallet from "assets/wallet-connect.png";
import coin from "assets/coin98.png";
import binance from "assets/binance.png";
import trust from "assets/trust-wallet.png";
import math from "assets/math.png";
import {
  connectMathWallet,
  connectTrustWallet,
  connectCoin98,
  connectMetamask,
  connectWalletConnect,
  connectBinanse,
} from "utils/conncetioncode.js";
import { useDispatch } from "react-redux";
import useReferrals from "hooks/useReferrals";
import { useTranslation } from "react-i18next";

function ConnectModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addNewRefShare } = useReferrals();

  function addRefLink(account) {
    addNewRefShare(account);
  }

  function handleWalletConnect() {
    connectWalletConnect(0).then((ret) => {
      if (ret.error === false) {
        dispatch({
          type: "web3Change",
          web3: ret.web3,
          userAddress: ret.acounts,
        });
        props.setShow(false);
        addRefLink(ret.acounts);
      } else {
        //console.log(ret.errorMessage);
      }
    });
  }

  function handleBinanse() {
    connectBinanse(0).then((ret) => {
      if (ret.error === false) {
        dispatch({
          type: "web3Change",
          web3: ret.web3,
          userAddress: ret.acounts,
        });
        props.setShow(false);
        addRefLink(ret.acounts);
      } else {
        //console.log(ret.errorMessage);
      }
    });
  }
  function handleCoin98() {
    connectCoin98(0).then((ret) => {
      if (ret.error === false) {
        dispatch({
          type: "web3Change",
          web3: ret.web3,
          userAddress: ret.acounts,
        });
        props.setShow(false);
        addRefLink(ret.acounts);
      } else {
        //console.log(ret.errorMessage);
      }
    });
  }

  function handleMathWallet() {
    connectMathWallet(0).then((ret) => {
      if (ret.error === false) {
        dispatch({
          type: "web3Change",
          web3: ret.web3,
          userAddress: ret.acounts,
        });
        props.setShow(false);
        addRefLink(ret.acounts);
      } else {
        //console.log(ret.errorMessage)
      }
    });
  }
  function handleTrustWallet() {
    connectTrustWallet(0).then((ret) => {
      if (ret.error === false) {
        dispatch({
          type: "web3Change",
          web3: ret.web3,
          userAddress: ret.acounts,
        });
        props.setShow(false);
        addRefLink(ret.acounts);
      } else {
        //console.log(ret.errorMessage)
      }
    });
  }

  function handleConnectMetamask() {
    connectMetamask(0).then((ret) => {
      if (ret.error === false) {
        dispatch({
          type: "web3Change",
          web3: ret.web3,
          userAddress: ret.acounts,
        });
        props.setShow(false);
        addRefLink(ret.acounts);
      } else {
        //console.log(ret.errorMessage);
      }
    });
  }

  return (
    <div
      className={styles.modal}
      onClick={() => {
        props.setShow(false);
      }}
    >
      <div
        className={styles.modalContent}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={styles.header}>{t("header.Connect_wallet_text")}</div>
        <div className={styles.body}>
          <div
            className={styles.col}
            onClick={() => {
              handleConnectMetamask();
              //console.log("start");
            }}
          >
            <img src={meta} className={styles.meta} alt="Metamask icon"></img>
            <div className={styles.txt}>{t("header.Metamask")}</div>
          </div>
          <div
            className={styles.col}
            onClick={() => {
              handleWalletConnect();
              //console.log("start");
            }}
          >
            <img src={wallet} className={styles.meta} alt="WalletConnect icon"></img>
            <div className={styles.txt}>{t("header.WalletConnect")}</div>
          </div>
          <div
            className={styles.col}
            onClick={() => {
              handleBinanse();
              //console.log("start");
            }}
          >
            <img src={binance} className={styles.meta} alt="Binance Wallet icon"></img>
            <div className={styles.txt}>{t("header.Binance")}</div>
          </div>
          <div
            className={styles.col}
            onClick={() => {
              handleCoin98();
              //console.log("start");
            }}
          >
            <img src={coin} className={styles.meta} alt="Coin98 icon"></img>
            <div className={styles.txt}>{t("header.Coin98")}</div>
          </div>
          <div
            className={styles.col}
            onClick={() => {
              handleMathWallet();
              //console.log("start");
            }}
          >
            <img src={math} className={styles.meta} alt="MathWallet icon"></img>
            <div className={styles.txt}>MathWallet</div>
          </div>
          <div
            className={styles.col}
            onClick={() => {
              handleTrustWallet();
              //console.log("start");
            }}
          >
            <img src={trust} className={styles.meta} alt="TrustWallet icon"></img>
            <div className={styles.txt}>TrustWallet</div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ConnectModal;
